
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "./css/list.css";

// import required modules
import { EffectCoverflow, Pagination } from "swiper";

import aw from '../assets/img/velaris/v1.png'
import aw1 from '../assets/img/velaris/v2.png'
import aw2 from '../assets/img/velaris/v3.PNG'
import aw3 from '../assets/img/velaris/v4.PNG'
import aw4 from '../assets/img/velaris/v5.PNG'
import aw5 from '../assets/img/velaris/v6.PNG'
import aw6 from '../assets/img/velaris/v8.PNG'
import aw7 from '../assets/img/velaris/v9.PNG'
import aw8 from '../assets/img/velaris/v10.PNG'
import aw9 from '../assets/img/velaris/v11.PNG'
import aw10 from '../assets/img/velaris/v12.PNG'
import aw11 from '../assets/img/velaris/v13.PNG'
import aw12 from '../assets/img/velaris/v14.PNG'
import aw13 from '../assets/img/velaris/v15.PNG'
import aw14 from '../assets/img/velaris/v16.PNG'
import aw15 from '../assets/img/velaris/v17.PNG'
import aw16 from '../assets/img/velaris/v18.PNG'




export default function App() {
  return (
    <div className="">
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={true}
        modules={[EffectCoverflow]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={aw} alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={aw1} alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={aw2} alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={aw3} alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={aw4} alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={aw5} alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={aw6} alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={aw7} alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={aw8} alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={aw9} alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={aw10} alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={aw11} alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={aw12} alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={aw13} alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={aw14} alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={aw15} alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={aw16} alt='' />
        </SwiperSlide>

      </Swiper>
    </div>
  );
}