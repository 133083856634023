import {RiTrophyLine, RiUserHeartLine, RiSuitcaseLine, RiLightbulbLine} from 'react-icons/ri';




// import agents images
import Agent6 from './assets/img/agents/agent6.png';
import Agent13 from './assets/img/agents/agent13.png';


// import bgc images
import Bgc1 from './assets/img/apartments/bgc1.jpeg';

import Condo1 from './assets/img/condo/condo1.jpeg';

import Condo8 from './assets/img/update/u2.jpg';

import Condo15 from './assets/img/condo/condo15.jpeg';

import Re1 from './assets/img/residential/re1.jpeg';

import Re8 from './assets/img/residential/re8.jpeg';

import Pn1 from './assets/img/residential/pn1.png';

import Ma1 from './assets/img/makati/ma1.jpeg';

import Ma9 from './assets/img/makati/ma9.jpeg';

import Ma15 from './assets/img/makati/ma15.png';

import Pa1 from './assets/img/pasig/pa1.jpeg';

import Pa4 from './assets/img/pasig/pa4.jpeg';

import Pa13 from './assets/img/pasig/pa13.jpeg';

import Pa22 from './assets/img/pasig/pa22.png';

import Ba2 from './assets/img/batangas/ba2.jpeg';

import Ba11 from './assets/img/batangas/ba11.jpeg';

import Ba19 from './assets/img/batangas/ba19.jpeg';

import Ce1 from './assets/img/cebu/ce1.jpeg';

import Al1 from './assets/img/alabang/al1.jpeg';

import Al17 from './assets/img/alabang/al17.jpeg';

import Al21 from './assets/img/alabang/al21.jpeg';

import Qe1 from './assets/img/quezon/qe1.jpeg';

import Ta1 from './assets/img/tanay/ta1.jpeg';

import La1 from './assets/img/lasp/la1.jpeg';

import Two from './assets/img/rent/two-maridien/r1.jpeg';

import Ho1 from './assets/img/rent/horizon-homes/h1.jpg';

import Ii1 from './assets/img/rent/infinity-tower/i1.jpeg';

import Pl1 from './assets/img/rent/pacific-plaza/p1.jpeg';

import U11 from './assets/img/update/u11.jpg';

import U34 from './assets/img/update/u34.jpg';

import U41 from './assets/img/update/u41.jpg';

import U53 from './assets/img/update/u53.jpg';

import U65 from './assets/img/update/u65.jpg';

import U81 from './assets/img/update/u80.jpg';

// import bgclg images
import Bgc1Lg from './assets/img/apartments/bgc1lg.jpeg';
import Bgc2Lg from './assets/img/apartments/bgc2lg.jpeg';
import Bgc3Lg from './assets/img/apartments/bgc3lg.jpeg';
import Bgc4Lg from './assets/img/apartments/bgc4lg.jpeg';
import Bgc5Lg from './assets/img/apartments/bgc5lg.jpeg';
import Bgc6Lg from './assets/img/apartments/bgc6lg.jpeg';
import Bgc7Lg from './assets/img/apartments/bgc7lg.jpeg';

import Condo1Lg from './assets/img/condo/condo1lg.jpeg';
import Condo2Lg from './assets/img/condo/condo2lg.jpeg';
import Condo3Lg from './assets/img/condo/condo3lg.jpeg';
import Condo4Lg from './assets/img/condo/condo4lg.jpeg';
import Condo5Lg from './assets/img/condo/condo5lg.jpeg';
import Condo6Lg from './assets/img/condo/condo6lg.jpeg';
import Condo7Lg from './assets/img/condo/condo7lg.jpeg';

import Condo8Lg from './assets/img/update/u1.jpg';
import Condo9Lg from './assets/img/update/u3.jpg';
import Condo10Lg from './assets/img/update/u4.jpg';
import Condo11Lg from './assets/img/update/u5.jpg';
import Condo12Lg from './assets/img/update/u6.jpg';
import Condo13Lg from './assets/img/update/u7.jpg';
import Condo14Lg from './assets/img/update/u8.jpg';

import Condo15Lg from './assets/img/condo/condo15lg.jpeg';
import Condo16Lg from './assets/img/condo/condo16lg.jpeg';
import Condo17Lg from './assets/img/condo/condo17lg.jpeg';
import Condo18Lg from './assets/img/condo/condo18lg.jpeg';
import Condo19Lg from './assets/img/condo/condo19lg.jpeg';
import Condo20Lg from './assets/img/condo/condo20lg.jpeg';
import Condo21Lg from './assets/img/condo/condo21lg.jpeg';

import Re1Lg from './assets/img/residential/re1lg.jpeg';
import Re2Lg from './assets/img/residential/re2lg.jpeg';
import Re3Lg from './assets/img/residential/re3lg.jpeg';
import Re4Lg from './assets/img/residential/re4lg.jpeg';
import Re5Lg from './assets/img/residential/re5lg.jpeg';
import Re6Lg from './assets/img/residential/re6lg.jpeg';
import Re7Lg from './assets/img/residential/re7lg.jpeg';

import Re8Lg from './assets/img/residential/re8lg.jpeg';
import Re9Lg from './assets/img/residential/re9lg.jpeg';
import Re10Lg from './assets/img/residential/re10lg.jpeg';
import Re11Lg from './assets/img/residential/re11lg.jpeg';
import Re12Lg from './assets/img/residential/re12lg.jpeg';
import Re13Lg from './assets/img/residential/re13lg.jpeg';
import Re14Lg from './assets/img/residential/re14lg.jpeg';

import Pn1Lg from './assets/img/residential/pn1lg.png';
import Pn2Lg from './assets/img/residential/pn2lg.png';
import Pn3Lg from './assets/img/residential/pn3lg.png';
import Pn4Lg from './assets/img/residential/pn4lg.png';
import Pn5Lg from './assets/img/residential/pn5lg.png';
import Pn6Lg from './assets/img/residential/pn6lg.png';

import Ma1Lg from './assets/img/makati/ma1lg.jpeg';
import Ma2Lg from './assets/img/makati/ma2lg.jpeg';
import Ma3Lg from './assets/img/makati/ma3lg.jpeg';
import Ma4Lg from './assets/img/makati/ma4lg.jpeg';
import Ma5Lg from './assets/img/makati/ma5lg.jpeg';
import Ma6Lg from './assets/img/makati/ma6lg.jpeg';
import Ma7Lg from './assets/img/makati/ma7lg.jpeg';

import Ma9Lg from './assets/img/makati/ma9lg.jpeg';
import Ma10Lg from './assets/img/makati/ma10lg.jpeg';
import Ma11Lg from './assets/img/makati/ma11lg.jpeg';
import Ma12Lg from './assets/img/makati/ma12lg.jpeg';
import Ma13Lg from './assets/img/makati/ma13lg.jpeg';
import Ma14Lg from './assets/img/makati/ma14lg.jpeg';

import Ma15Lg from './assets/img/makati/ma15lg.png';
import Ma16Lg from './assets/img/makati/ma16lg.png';
import Ma17Lg from './assets/img/makati/ma17lg.png';
import Ma18Lg from './assets/img/makati/ma18lg.png';
import Ma19Lg from './assets/img/makati/ma19lg.png';
import Ma20Lg from './assets/img/makati/ma20lg.png';
import Ma21Lg from './assets/img/makati/ma21lg.png';
import Ma22Lg from './assets/img/makati/ma22lg.png';
import Ma23Lg from './assets/img/makati/ma23lg.png';
import Ma24Lg from './assets/img/makati/ma24lg.png';
import Ma25Lg from './assets/img/makati/ma25lg.png';
import Ma26Lg from './assets/img/makati/ma26lg.png';
import Ma27Lg from './assets/img/makati/ma27lg.png';
import Ma28Lg from './assets/img/makati/ma28lg.png';
import Ma29Lg from './assets/img/makati/ma29lg.png';
import Ma30Lg from './assets/img/makati/ma30lg.png';

import Pa1Lg from './assets/img/pasig/pa1lg.jpeg';
import Pa2Lg from './assets/img/pasig/pa2lg.jpeg';
import Pa3Lg from './assets/img/pasig/pa3lg.jpeg';

import Pa4Lg from './assets/img/pasig/pa4lg.jpeg';
import Pa5Lg from './assets/img/pasig/pa5lg.jpeg';
import Pa6Lg from './assets/img/pasig/pa6lg.jpeg';
import Pa7Lg from './assets/img/pasig/pa7lg.jpeg';
import Pa8Lg from './assets/img/pasig/pa8lg.jpeg';
import Pa9Lg from './assets/img/pasig/pa9lg.jpeg';
import Pa10Lg from './assets/img/pasig/pa10lg.jpeg';
import Pa11Lg from './assets/img/pasig/pa11lg.jpeg';
import Pa12Lg from './assets/img/pasig/pa12lg.jpeg';

import Pa13Lg from './assets/img/pasig/pa13lg.jpeg';
import Pa14Lg from './assets/img/pasig/pa14lg.jpeg';
import Pa15Lg from './assets/img/pasig/pa15lg.jpeg';
import Pa16Lg from './assets/img/pasig/pa16lg.jpeg';
import Pa17Lg from './assets/img/pasig/pa17lg.jpeg';
import Pa18Lg from './assets/img/pasig/pa18lg.jpeg';
import Pa19Lg from './assets/img/pasig/pa19lg.jpeg';
import Pa20Lg from './assets/img/pasig/pa20lg.jpeg';
import Pa21Lg from './assets/img/pasig/pa21lg.jpeg';

import Pa22Lg from './assets/img/pasig/pa22lg.png';
import Pa23Lg from './assets/img/pasig/pa23lg.png';
import Pa24Lg from './assets/img/pasig/pa24lg.png';
import Pa25Lg from './assets/img/pasig/pa25lg.png';
import Pa26Lg from './assets/img/pasig/pa26lg.png';
import Pa27Lg from './assets/img/pasig/pa27lg.png';
import Pa28Lg from './assets/img/pasig/pa28lg.png';
import Pa29Lg from './assets/img/pasig/pa29lg.png';

import Ba1Lg from './assets/img/batangas/ba1lg.jpeg';
import Ba2Lg from './assets/img/batangas/ba2lg.jpeg';
import Ba3Lg from './assets/img/batangas/ba3lg.jpeg';
import Ba4Lg from './assets/img/batangas/ba4lg.jpeg';
import Ba5Lg from './assets/img/batangas/ba5lg.jpeg';
import Ba6Lg from './assets/img/batangas/ba6lg.jpeg';
import Ba7Lg from './assets/img/batangas/ba7lg.jpeg';
import Ba8Lg from './assets/img/batangas/ba8lg.jpeg';
import Ba9Lg from './assets/img/batangas/ba9lg.jpeg';
import Ba100Lg from './assets/img/batangas/ba100.png';

import Ba11Lg from './assets/img/batangas/ba11lg.jpeg';
import Ba12Lg from './assets/img/batangas/ba12lg.jpeg';
import Ba13Lg from './assets/img/batangas/ba13lg.jpeg';
import Ba14Lg from './assets/img/batangas/ba14lg.jpeg';
import Ba15Lg from './assets/img/batangas/ba15lg.jpeg';
import Ba16Lg from './assets/img/batangas/ba16lg.jpeg';
import Ba17Lg from './assets/img/batangas/ba17lg.jpeg';
import Ba18Lg from './assets/img/batangas/ba18lg.jpeg';

import Ba19Lg from './assets/img/batangas/ba19lg.jpeg';
import Ba20Lg from './assets/img/batangas/ba20lg.jpeg';
import Ba21Lg from './assets/img/batangas/ba21lg.jpeg';

import Ce1Lg from './assets/img/cebu/ce1lg.jpeg';
import Ce2Lg from './assets/img/cebu/ce2lg.jpeg';
import Ce3Lg from './assets/img/cebu/ce3lg.jpeg';
import Ce4Lg from './assets/img/cebu/ce4lg.jpeg';
import Ce5Lg from './assets/img/cebu/ce5lg.jpeg';
import Ce6Lg from './assets/img/cebu/ce6lg.jpeg';

import Al1Lg from './assets/img/alabang/al1lg.jpeg';
import Al2Lg from './assets/img/alabang/al2lg.jpeg';
import Al3Lg from './assets/img/alabang/al3lg.jpeg';
import Al4Lg from './assets/img/alabang/al4lg.jpeg';
import Al5Lg from './assets/img/alabang/al5lg.jpeg';
import Al6Lg from './assets/img/alabang/al6lg.jpeg';
import Al7Lg from './assets/img/alabang/al7lg.jpeg';
import Al8Lg from './assets/img/alabang/al8lg.jpeg';
import Al9Lg from './assets/img/alabang/al9lg.jpeg';
import Al10Lg from './assets/img/alabang/al10lg.jpeg';

import Al16Lg from './assets/img/alabang/al16lg.jpeg';
import Al17Lg from './assets/img/alabang/al17lg.jpeg';
import Al18Lg from './assets/img/alabang/al18lg.jpeg';
import Al19Lg from './assets/img/alabang/al19lg.jpeg';

import Al20Lg from './assets/img/alabang/al20lg.jpeg';
import Al21Lg from './assets/img/alabang/al21lg.jpeg';
import Al22Lg from './assets/img/alabang/al22lg.jpeg';
import Al23Lg from './assets/img/alabang/al23lg.jpeg';
import Al24Lg from './assets/img/alabang/al24lg.jpeg';
import Al25Lg from './assets/img/alabang/al25lg.jpeg';
import Al26Lg from './assets/img/alabang/al26lg.jpeg';
import Al27Lg from './assets/img/alabang/al27lg.jpeg';
import Al28Lg from './assets/img/alabang/al28lg.jpeg';
import Al29Lg from './assets/img/alabang/al29lg.jpeg';
import Al30Lg from './assets/img/alabang/al30lg.jpeg';
import Al31Lg from './assets/img/alabang/al31lg.jpeg';
import Al32Lg from './assets/img/alabang/al32lg.jpeg';

import Qe1Lg from './assets/img/quezon/qe1lg.jpeg';
import Qe2Lg from './assets/img/quezon/qe2lg.jpeg';
import Qe3Lg from './assets/img/quezon/qe3lg.jpeg';
import Qe4Lg from './assets/img/quezon/qe4lg.jpeg';
import Qe5Lg from './assets/img/quezon/qe5lg.jpeg';
import Qe6Lg from './assets/img/quezon/qe6lg.jpeg';
import Qe7Lg from './assets/img/quezon/qe7lg.jpeg';
import Qe8Lg from './assets/img/quezon/qe8lg.jpeg';
import Qe9Lg from './assets/img/quezon/qe9lg.jpeg';
import Qe10Lg from './assets/img/quezon/qe10lg.jpeg';

import Ta1Lg from './assets/img/tanay/ta1lg.jpeg';
import Ta2Lg from './assets/img/tanay/ta2lg.jpeg';
import Ta3Lg from './assets/img/tanay/ta3lg.jpeg';
import Ta4Lg from './assets/img/tanay/ta4lg.jpeg';
import Ta5Lg from './assets/img/tanay/ta5lg.jpeg';
import Ta6Lg from './assets/img/tanay/ta6lg.jpeg';
import Ta7Lg from './assets/img/tanay/ta7lg.jpeg';
import Ta8Lg from './assets/img/tanay/ta8lg.jpeg';
import Ta9Lg from './assets/img/tanay/ta9lg.jpeg';
import Ta10Lg from './assets/img/tanay/ta10lg.jpeg';
import Ta11Lg from './assets/img/tanay/ta11lg.jpeg';
import Ta12Lg from './assets/img/tanay/ta12lg.jpeg';
import Ta13Lg from './assets/img/tanay/ta13lg.jpeg';
import Ta14Lg from './assets/img/tanay/ta14lg.jpeg';

import La1Lg from './assets/img/lasp/la1lg.jpeg';
import La2Lg from './assets/img/lasp/la2lg.jpeg';
import La3Lg from './assets/img/lasp/la3lg.jpeg';
import La4Lg from './assets/img/lasp/la4lg.jpeg';
import La5Lg from './assets/img/lasp/la5lg.jpeg';
import La6Lg from './assets/img/lasp/la6lg.jpeg';
import La7Lg from './assets/img/lasp/la7lg.jpeg';
import La8Lg from './assets/img/lasp/la8lg.jpeg';

import TwoLg from './assets/img/rent/two-maridien/r1lg.jpeg'
import Two2Lg from './assets/img/rent/two-maridien/r2lg.jpeg'
import Two3Lg from './assets/img/rent/two-maridien/r3lg.jpeg'
import Two4Lg from './assets/img/rent/two-maridien/r4lg.jpeg'
import Two5Lg from './assets/img/rent/two-maridien/r5lg.jpeg'
import Two6Lg from './assets/img/rent/two-maridien/r6lg.jpeg'
import Two7Lg from './assets/img/rent/two-maridien/r7lg.jpeg'
import Two8Lg from './assets/img/rent/two-maridien/r8lg.jpeg'
import Two9Lg from './assets/img/rent/two-maridien/r9lg.jpeg'
import Two10Lg from './assets/img/rent/two-maridien/r10lg.jpeg'
import Two11Lg from './assets/img/rent/two-maridien/r11lg.jpeg'

import H1Lg from './assets/img/rent/horizon-homes/h1lg.jpg';
import H2Lg from './assets/img/rent/horizon-homes/h2lg.jpg';
import H3Lg from './assets/img/rent/horizon-homes/h3lg.jpg';
import H4Lg from './assets/img/rent/horizon-homes/h4lg.jpg';
import H5Lg from './assets/img/rent/horizon-homes/h5lg.jpg';
import H6Lg from './assets/img/rent/horizon-homes/h6lg.jpg';
import H7Lg from './assets/img/rent/horizon-homes/h7lg.jpg';
import H8Lg from './assets/img/rent/horizon-homes/h8lg.jpg';
import H9Lg from './assets/img/rent/horizon-homes/h9lg.jpg';
import H10Lg from './assets/img/rent/horizon-homes/h10lg.jpg';
import H11Lg from './assets/img/rent/horizon-homes/h11lg.jpg';
import H12Lg from './assets/img/rent/horizon-homes/h12lg.jpg';
import H13Lg from './assets/img/rent/horizon-homes/h13lg.jpg';

import I1Lg from './assets/img/rent/infinity-tower/i1lg.jpeg';
import I2Lg from './assets/img/rent/infinity-tower/i2lg.jpeg';
import I3Lg from './assets/img/rent/infinity-tower/i3lg.jpeg';
import I4Lg from './assets/img/rent/infinity-tower/i4lg.jpeg';
import I5Lg from './assets/img/rent/infinity-tower/i5lg.jpeg';
import I6Lg from './assets/img/rent/infinity-tower/i6lg.jpeg';
import I7Lg from './assets/img/rent/infinity-tower/i7lg.jpeg';

import Pl1Lg from './assets/img/rent/pacific-plaza/p1lg.jpeg';
import Pl2Lg from './assets/img/rent/pacific-plaza/p2lg.jpeg';
import Pl3Lg from './assets/img/rent/pacific-plaza/p3lg.jpeg';
import Pl4Lg from './assets/img/rent/pacific-plaza/p4lg.jpeg';
import Pl5Lg from './assets/img/rent/pacific-plaza/p5lg.jpeg';
import Pl6Lg from './assets/img/rent/pacific-plaza/p6lg.jpeg';
import Pl7Lg from './assets/img/rent/pacific-plaza/p7lg.jpeg';
import Pl8Lg from './assets/img/rent/pacific-plaza/p8lg.jpeg';
import Pl9Lg from './assets/img/rent/pacific-plaza/p9lg.jpeg';
import Pl10Lg from './assets/img/rent/pacific-plaza/p10lg.jpeg';
import Pl11Lg from './assets/img/rent/pacific-plaza/p11lg.jpeg';
import Pl12Lg from './assets/img/rent/pacific-plaza/p12lg.jpeg';
import Pl13Lg from './assets/img/rent/pacific-plaza/p13lg.jpeg';
import Pl14Lg from './assets/img/rent/pacific-plaza/p14lg.jpeg';
import Pl15Lg from './assets/img/rent/pacific-plaza/p15lg.jpeg';
import Pl16Lg from './assets/img/rent/pacific-plaza/p16lg.jpeg';
import Pl17Lg from './assets/img/rent/pacific-plaza/p17lg.jpeg';
import Pl18Lg from './assets/img/rent/pacific-plaza/p18lg.jpeg';
import Pl19Lg from './assets/img/rent/pacific-plaza/p19lg.jpeg';
import Pl20Lg from './assets/img/rent/pacific-plaza/p20lg.jpeg';

import U11Lg from './assets/img/update/u11.jpg';
import U12Lg from './assets/img/update/u12.jpg';
import U13Lg from './assets/img/update/u13.jpg';
import U14Lg from './assets/img/update/u14.jpg';
import U15Lg from './assets/img/update/u15.jpg';
import U16Lg from './assets/img/update/u16.jpg';
import U17Lg from './assets/img/update/u17.jpg';
import U18Lg from './assets/img/update/u18.jpg';
import U19Lg from './assets/img/update/u19.jpg';
import U20Lg from './assets/img/update/u20.jpg';
import U21Lg from './assets/img/update/u21.jpg';
import U22Lg from './assets/img/update/u22.jpg';
import U23Lg from './assets/img/update/u23.jpg';
import U24Lg from './assets/img/update/u24.jpg';
import U25Lg from './assets/img/update/u25.jpg';
import U26Lg from './assets/img/update/u26.jpg';
import U27Lg from './assets/img/update/u27.jpg';
import U28Lg from './assets/img/update/u28.jpg';
import U29Lg from './assets/img/update/u29.jpg';

import U34Lg from './assets/img/update/u34.jpg';
import U35Lg from './assets/img/update/u35.jpg';
import U36Lg from './assets/img/update/u36.jpg';
import U37Lg from './assets/img/update/u37.jpg';
import U38Lg from './assets/img/update/u38.jpg';
import U39Lg from './assets/img/update/u39.jpg';
import U40Lg from './assets/img/update/u40.jpg';


import U41Lg from './assets/img/update/u41.jpg';
import U42Lg from './assets/img/update/u42.jpg';
import U43Lg from './assets/img/update/u43.jpg';
import U44Lg from './assets/img/update/u44.jpg';
import U45Lg from './assets/img/update/u45.jpg';
import U46Lg from './assets/img/update/u46.jpg';
import U47Lg from './assets/img/update/u47.jpg';
import U48Lg from './assets/img/update/u48.jpg';
import U49Lg from './assets/img/update/u49.jpg';
import U50Lg from './assets/img/update/u50.jpg';
import U51Lg from './assets/img/update/u51.jpg';
import U52Lg from './assets/img/update/u52.jpg';

import U53Lg from './assets/img/update/u53.jpg';
import U54Lg from './assets/img/update/u54.jpg';
import U56Lg from './assets/img/update/u56.jpg';
import U57Lg from './assets/img/update/u57.jpg';
import U58Lg from './assets/img/update/u58.jpg';
import U59Lg from './assets/img/update/u59.jpg';
import U60Lg from './assets/img/update/u60.jpg';
import U61Lg from './assets/img/update/u61.jpg';
import U62Lg from './assets/img/update/u62.jpg';
import U63Lg from './assets/img/update/u63.jpg';

import U65Lg from './assets/img/update/u65.jpg';
import U66Lg from './assets/img/update/u66.jpg';
import U67Lg from './assets/img/update/u67.jpg';
import U68Lg from './assets/img/update/u68.jpg';
import U69Lg from './assets/img/update/u69.jpg';
import U70Lg from './assets/img/update/u70.jpg';
import U71Lg from './assets/img/update/u71.jpg';
import U72Lg from './assets/img/update/u72.jpg';
import U73Lg from './assets/img/update/u73.jpg';
import U74Lg from './assets/img/update/u74.jpg';
import U75Lg from './assets/img/update/u75.jpg';
import U76Lg from './assets/img/update/u76.jpg';
import U77Lg from './assets/img/update/u77.jpg';
import U78Lg from './assets/img/update/u78.jpg';
import U79Lg from './assets/img/update/u79.jpg';

import U80Lg from './assets/img/update/u80.jpg';
import U81Lg from './assets/img/update/u81.jpg';
import U82Lg from './assets/img/update/u82.jpg';
import U83Lg from './assets/img/update/u83.jpg';
import U84Lg from './assets/img/update/u84.jpg';
import U85Lg from './assets/img/update/u85.jpg';
import U86Lg from './assets/img/update/u86.jpg';
import U87Lg from './assets/img/update/u87.jpg';
import U89Lg from './assets/img/update/u89.jpg';
import U90Lg from './assets/img/update/u90.jpg';
import U91Lg from './assets/img/update/u91.jpg';
import U92Lg from './assets/img/update/u92.jpg';
import U93Lg from './assets/img/update/u93.jpg';


//icons
import LiveIcon1 from './assets/img/logo/logo1.png';
import LiveIcon2 from './assets/img/logo/logo2.png';
import LiveIcon3 from './assets/img/logo/logo3.png';
import LiveIcon4 from './assets/img/logo/logo4.png';
import LiveIcon5 from './assets/img/logo/logo5.png';
import LiveIcon6 from './assets/img/logo/logo6.png';
import LiveIcon9 from './assets/img/logo-1.jpg';


//agents
import LiveIcon7 from './assets/img/agents/agent13.png';
import LiveIcon8 from './assets/img/agents/agent14.png';

import LiveIcon10 from './assets/img/agents/agent14-full.PNG';

import cover1 from'./assets/img/h1.png'
import cover2 from'./assets/img/h2.png'
import cover3 from'./assets/img/h3.png'
import cover4 from'./assets/img/h4.png'
import cover6 from './assets/img/h6.png'

import cover7 from './assets/img/velaris/v1.png'
import cover8 from './assets/img/lindgren/t1.PNG'
import cover9 from './assets/img/federal/fed1.PNG'
import cover10 from './assets/img/mckinley/mi1.PNG'
import cover11 from './assets/img/anaya/ana1.PNG'
import cover12 from './assets/img/hamptons/hamp1.PNG'


export const nav = [
  {
    text: "Home",
    path: "/",
  },
  {
    text: "About Us", 
    path: "/about",
  },
  {
    text: "Blog",
    path: "/contact",
  },
  {
    text: "Services",
    path: "/service",
  },
  {
    text: "Contact Us",
    path: "/contact",
  },


]




export const featured = [
  {
    cover:cover1,
    name: "Family House",
    total: "122 Property",
  },
  {
    cover:cover2,
    name: "House & Villa",
    total: "155 Property",
  },
  {
    cover:cover3,
    name: "Apartment",
    total: "300 Property",
  },
  {
    cover:cover4,
    name: "Office & Studio",
    total: "80 Property",
  },
  {
    cover:cover6,
    name: "Villa & Condo",
    total: "80 Property",
  },
]

export const displayAgent = [
  {
    iconDisplay1:LiveIcon7,
    iconDisplay2:LiveIcon8,
    iconDisplay3:LiveIcon10,

    socialIcon1:LiveIcon1,
    socialIcon2:LiveIcon2,
    socialIcon3:LiveIcon3,
    socialIcon4:LiveIcon4,
    socialIcon5:LiveIcon5,
    socialIcon6:LiveIcon6,

    socialIcon7:LiveIcon9,
   


  }
]


export const awards = [
  {
    icon: <i> <RiTrophyLine className='fill-yellow-600'/> </i>,
    num: "32 M	",
    name: "Blue Burmin Award",
  },
  {
    icon: <i> <RiSuitcaseLine className='fill-blue-600'/> </i>,
    num: "43 M",
    name: "Mimo X11 Award",
  },
  {
    icon: <i> <RiLightbulbLine className='fill-orange-500'/> </i>,
    num: "51 M",
    name: "Australian UGC Award",
  },
  {
    icon: <i> <RiUserHeartLine className='fill-red-600 '/> </i>,
    num: "42 M",
    name: "IITCA Green Award",
  },
  
]

export const location = [
  {
    id: 1,
    name: "The Velaris",
    Villas: <p className='text-justify'>For those who have emerged victorious from life’s challenges, the successful few whose experience and expertise have given them a certain discernment, only the masterful will do.</p>,
    Apartments: "10 Apartments",
    Offices: "07 Offices",
    cover:cover7,
  },
  {
    id: 2,
    name: "Jerrsy, United State",
    Villas: "12 Villas",
    Apartments: "10 Apartments",
    Offices: "07 Offices",
    cover:cover8,
  },
  {
    id: 3,
    name: "Liverpool, London",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover:cover9,
  },
  {
    id: 4,
    name: "NewYork, United States",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover:cover10,
  },
  {
    id: 5,
    name: "Montreal, Canada",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover:cover11,
  },
  {
    id: 6,
    name: "California, USA",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover:cover12,
  },
]
export const users = [
  {
    id: 1,
    firstName: "Claudio",
    lastName: "Hoopper",
    email: "choopper0@yolasite.com",
    age: 33
  },
  {
    id: 2,
    firstName: "Roxine",
    lastName: "Midford",
    email: "rmidford1@washington.edu",
    age: 57
  },
  {
    id: 3,
    firstName: "Brook",
    lastName: "O'Gaven",
    email: "bogaven2@pen.io",
    age: 29
  },
  {
    id: 4,
    firstName: "Dedra",
    lastName: "Hoggin",
    email: "dhoggin3@cam.ac.uk",
    age: 54
  },
  {
    id: 5,
    firstName: "Darice",
    lastName: "Gandrich",
    email: "dgandrich4@zimbio.com",
    age: 23
  },
  {
    id: 6,
    firstName: "Florida",
    lastName: "Wringe",
    email: "fwringe5@pbs.org",
    age: 38
  },
  {
    id: 7,
    firstName: "Tybi",
    lastName: "Moyne",
    email: "tmoyne6@nih.gov",
    age: 39
  },
  {
    id: 8,
    firstName: "Julita",
    lastName: "Snary",
    email: "jsnary7@census.gov",
    age: 41
  },
  {
    id: 9,
    firstName: "Say",
    lastName: "Kaasmann",
    email: "skaasmann8@furl.net",
    age: 36
  },
  {
    id: 10,
    firstName: "Haroun",
    lastName: "Leatt",
    email: "hleatt9@facebook.com",
    age: 76
  },
  {
    id: 11,
    firstName: "Paige",
    lastName: "Bendley",
    email: "pbendleya@hugedomains.com",
    age: 27
  },
  {
    id: 12,
    firstName: "Sada",
    lastName: "Critoph",
    email: "scritophb@domainmarket.com",
    age: 24
  },
  {
    id: 13,
    firstName: "Jessa",
    lastName: "Loxton",
    email: "jloxtonc@google.es",
    age: 69
  },
  {
    id: 14,
    firstName: "Reginauld",
    lastName: "Garroway",
    email: "rgarrowayd@irs.gov",
    age: 67
  },
  {
    id: 15,
    firstName: "Oswald",
    lastName: "Teggart",
    email: "oteggarte@yahoo.com",
    age: 24
  },
  {
    id: 16,
    firstName: "Francesca",
    lastName: "Jeratt",
    email: "fjerattf@infoseek.co.jp",
    age: 47
  },
  {
    id: 17,
    firstName: "Deane",
    lastName: "Blanning",
    email: "dblanningg@go.com",
    age: 39
  },
  {
    id: 18,
    firstName: "Link",
    lastName: "Maxworthy",
    email: "lmaxworthyh@nhs.uk",
    age: 63
  },
  {
    id: 19,
    firstName: "Belva",
    lastName: "Bouttell",
    email: "bbouttelli@goo.ne.jp",
    age: 58
  },
  {
    id: 20,
    firstName: "Cristobal",
    lastName: "Ormesher",
    email: "cormesherj@themeforest.net",
    age: 42
  },
  {
    id: 21,
    firstName: "Cristina",
    lastName: "Geer",
    email: "cgeerk@mozilla.com",
    age: 84
  },
  {
    id: 22,
    firstName: "Rosalinde",
    lastName: "Kurt",
    email: "rkurtl@xinhuanet.com",
    age: 73
  },
  {
    id: 23,
    firstName: "Mart",
    lastName: "Giraudat",
    email: "mgiraudatm@mozilla.org",
    age: 35
  },
  {
    id: 24,
    firstName: "Morrie",
    lastName: "Goodbarr",
    email: "mgoodbarrn@dailymotion.com",
    age: 58
  },
  {
    id: 25,
    firstName: "Linet",
    lastName: "Stanway",
    email: "lstanwayo@si.edu",
    age: 44
  },
  {
    id: 26,
    firstName: "Jackson",
    lastName: "Fleckno",
    email: "jflecknop@uiuc.edu",
    age: 89
  },
  {
    id: 27,
    firstName: "Gunner",
    lastName: "Seel",
    email: "gseelq@nih.gov",
    age: 65
  },
  {
    id: 28,
    firstName: "Dorthy",
    lastName: "Gilchrist",
    email: "dgilchristr@hud.gov",
    age: 59
  },
  {
    id: 29,
    firstName: "Kathryne",
    lastName: "Hurst",
    email: "khursts@dot.gov",
    age: 94
  },
  {
    id: 30,
    firstName: "Nerti",
    lastName: "Zottoli",
    email: "nzottolit@wikia.com",
    age: 18
  },
  {
    id: 31,
    firstName: "Judy",
    lastName: "Corradini",
    email: "jcorradiniu@bluehost.com",
    age: 30
  },
  {
    id: 32,
    firstName: "Luelle",
    lastName: "Prowting",
    email: "lprowtingv@about.com",
    age: 70
  },
  {
    id: 33,
    firstName: "Theodore",
    lastName: "Jacobowicz",
    email: "tjacobowiczw@aol.com",
    age: 78
  },
  {
    id: 34,
    firstName: "Jacquie",
    lastName: "Wailes",
    email: "jwailesx@fema.gov",
    age: 73
  },
  {
    id: 35,
    firstName: "Marcel",
    lastName: "Vasiljevic",
    email: "mvasiljevicy@yelp.com",
    age: 38
  },
  {
    id: 36,
    firstName: "Joli",
    lastName: "Schuelcke",
    email: "jschuelckez@bluehost.com",
    age: 27
  },
  {
    id: 37,
    firstName: "Hermie",
    lastName: "Luetchford",
    email: "hluetchford10@yelp.com",
    age: 54
  },
  {
    id: 38,
    firstName: "Sigvard",
    lastName: "Lesurf",
    email: "slesurf11@desdev.cn",
    age: 99
  },
  {
    id: 39,
    firstName: "Prinz",
    lastName: "Tugman",
    email: "ptugman12@canalblog.com",
    age: 81
  },
  {
    id: 40,
    firstName: "Gordon",
    lastName: "Bello",
    email: "gbello13@mail.ru",
    age: 92
  },
  {
    id: 41,
    firstName: "Lewie",
    lastName: "Willis",
    email: "lwillis14@who.int",
    age: 70
  },
  {
    id: 42,
    firstName: "Hyacintha",
    lastName: "Etheredge",
    email: "hetheredge15@studiopress.com",
    age: 67
  },
  {
    id: 43,
    firstName: "Kelsi",
    lastName: "Liptrot",
    email: "kliptrot16@chron.com",
    age: 38
  },
  {
    id: 44,
    firstName: "Aurel",
    lastName: "Wreakes",
    email: "awreakes17@examiner.com",
    age: 33
  },
  {
    id: 45,
    firstName: "Efren",
    lastName: "Insworth",
    email: "einsworth18@webeden.co.uk",
    age: 64
  },
  {
    id: 46,
    firstName: "Jobyna",
    lastName: "Caulwell",
    email: "jcaulwell19@altervista.org",
    age: 29
  },
  {
    id: 47,
    firstName: "Gwenni",
    lastName: "Harrod",
    email: "gharrod1a@prnewswire.com",
    age: 89
  },
  {
    id: 48,
    firstName: "Lindy",
    lastName: "Habbes",
    email: "lhabbes1b@a8.net",
    age: 35
  },
  {
    id: 49,
    firstName: "Eunice",
    lastName: "Preuvost",
    email: "epreuvost1c@nih.gov",
    age: 43
  },
  {
    id: 50,
    firstName: "Wesley",
    lastName: "Thomerson",
    email: "wthomerson1d@yahoo.co.jp",
    age: 99
  },
  {
    id: 51,
    firstName: "Carmella",
    lastName: "Divis",
    email: "cdivis1e@canalblog.com",
    age: 79
  },
  {
    id: 52,
    firstName: "Lydia",
    lastName: "Janic",
    email: "ljanic1f@flickr.com",
    age: 38
  },
  {
    id: 53,
    firstName: "Elisha",
    lastName: "Wornum",
    email: "ewornum1g@google.it",
    age: 81
  },
  {
    id: 54,
    firstName: "Geneva",
    lastName: "Rable",
    email: "grable1h@ox.ac.uk",
    age: 32
  },
  {
    id: 55,
    firstName: "Fredi",
    lastName: "Forker",
    email: "fforker1i@reverbnation.com",
    age: 78
  },
  {
    id: 56,
    firstName: "Erny",
    lastName: "Ireson",
    email: "eireson1j@barnesandnoble.com",
    age: 18
  },
  {
    id: 57,
    firstName: "Cinda",
    lastName: "Cherry",
    email: "ccherry1k@squarespace.com",
    age: 70
  },
  {
    id: 58,
    firstName: "Stoddard",
    lastName: "Chainey",
    email: "schainey1l@baidu.com",
    age: 88
  },
  {
    id: 59,
    firstName: "Kelly",
    lastName: "McGeouch",
    email: "kmcgeouch1m@globo.com",
    age: 87
  },
  {
    id: 60,
    firstName: "Klement",
    lastName: "Harcombe",
    email: "kharcombe1n@yandex.ru",
    age: 81
  },
  {
    id: 61,
    firstName: "Siffre",
    lastName: "Tebbut",
    email: "stebbut1o@free.fr",
    age: 43
  },
  {
    id: 62,
    firstName: "Jermayne",
    lastName: "Petkovic",
    email: "jpetkovic1p@xing.com",
    age: 64
  },
  {
    id: 63,
    firstName: "Jobie",
    lastName: "Crossdale",
    email: "jcrossdale1q@nydailynews.com",
    age: 61
  },
  {
    id: 64,
    firstName: "Cedric",
    lastName: "Topham",
    email: "ctopham1r@gnu.org",
    age: 60
  },
  {
    id: 65,
    firstName: "Norton",
    lastName: "Kingstne",
    email: "nkingstne1s@yolasite.com",
    age: 76
  },
  {
    id: 66,
    firstName: "Gussi",
    lastName: "Neild",
    email: "gneild1t@japanpost.jp",
    age: 82
  },
  {
    id: 67,
    firstName: "Shelly",
    lastName: "Negro",
    email: "snegro1u@vk.com",
    age: 97
  },
  {
    id: 68,
    firstName: "Sharyl",
    lastName: "Corsan",
    email: "scorsan1v@pbs.org",
    age: 40
  },
  {
    id: 69,
    firstName: "Lacie",
    lastName: "Mollnar",
    email: "lmollnar1w@reuters.com",
    age: 46
  },
  {
    id: 70,
    firstName: "Carleton",
    lastName: "Kettlestringe",
    email: "ckettlestringe1x@bloomberg.com",
    age: 57
  },
  {
    id: 71,
    firstName: "Briggs",
    lastName: "Fosdick",
    email: "bfosdick1y@infoseek.co.jp",
    age: 93
  },
  {
    id: 72,
    firstName: "Josephina",
    lastName: "Ferrao",
    email: "jferrao1z@quantcast.com",
    age: 96
  },
  {
    id: 73,
    firstName: "Freddie",
    lastName: "Sansum",
    email: "fsansum20@gov.uk",
    age: 40
  },
  {
    id: 74,
    firstName: "Rowney",
    lastName: "Ewert",
    email: "rewert21@vimeo.com",
    age: 96
  },
  {
    id: 75,
    firstName: "Fraser",
    lastName: "Servis",
    email: "fservis22@360.cn",
    age: 52
  },
  {
    id: 76,
    firstName: "Caryl",
    lastName: "Backsal",
    email: "cbacksal23@epa.gov",
    age: 20
  },
  {
    id: 77,
    firstName: "Monro",
    lastName: "Purcer",
    email: "mpurcer24@stanford.edu",
    age: 52
  },
  {
    id: 78,
    firstName: "Bradan",
    lastName: "Dziwisz",
    email: "bdziwisz25@admin.ch",
    age: 78
  },
  {
    id: 79,
    firstName: "Teodora",
    lastName: "Geertsen",
    email: "tgeertsen26@nasa.gov",
    age: 47
  },
  {
    id: 80,
    firstName: "Janice",
    lastName: "Prestland",
    email: "jprestland27@canalblog.com",
    age: 78
  },
  {
    id: 81,
    firstName: "Madelyn",
    lastName: "Lanchbery",
    email: "mlanchbery28@sakura.ne.jp",
    age: 39
  },
  {
    id: 82,
    firstName: "Carlin",
    lastName: "Adrian",
    email: "cadrian29@photobucket.com",
    age: 58
  },
  {
    id: 83,
    firstName: "Larine",
    lastName: "Stubbings",
    email: "lstubbings2a@prweb.com",
    age: 29
  },
  {
    id: 84,
    firstName: "Lane",
    lastName: "Morrant",
    email: "lmorrant2b@wix.com",
    age: 52
  },
  {
    id: 85,
    firstName: "Bevin",
    lastName: "Shepstone",
    email: "bshepstone2c@state.tx.us",
    age: 49
  },
  {
    id: 86,
    firstName: "Lorene",
    lastName: "Escritt",
    email: "lescritt2d@abc.net.au",
    age: 59
  },
  {
    id: 87,
    firstName: "Lona",
    lastName: "Cockitt",
    email: "lcockitt2e@businessweek.com",
    age: 82
  },
  {
    id: 88,
    firstName: "Anabelle",
    lastName: "Auston",
    email: "aauston2f@stumbleupon.com",
    age: 38
  },
  {
    id: 89,
    firstName: "Laraine",
    lastName: "Birchenhead",
    email: "lbirchenhead2g@marriott.com",
    age: 46
  },
  {
    id: 90,
    firstName: "Milena",
    lastName: "Carnachen",
    email: "mcarnachen2h@archive.org",
    age: 56
  },
  {
    id: 91,
    firstName: "Arney",
    lastName: "Whaites",
    email: "awhaites2i@illinois.edu",
    age: 32
  },
  {
    id: 92,
    firstName: "Leonanie",
    lastName: "O'Bradane",
    email: "lobradane2j@oakley.com",
    age: 99
  },
  {
    id: 93,
    firstName: "Mickie",
    lastName: "Kob",
    email: "mkob2k@tamu.edu",
    age: 96
  },
  {
    id: 94,
    firstName: "Friederike",
    lastName: "Parkman",
    email: "fparkman2l@usa.gov",
    age: 55
  },
  {
    id: 95,
    firstName: "Hadria",
    lastName: "Gotcliff",
    email: "hgotcliff2m@google.com.hk",
    age: 34
  },
  {
    id: 96,
    firstName: "Vittorio",
    lastName: "Stairs",
    email: "vstairs2n@technorati.com",
    age: 22
  },
  {
    id: 97,
    firstName: "Daisi",
    lastName: "Forsdicke",
    email: "dforsdicke2o@jiathis.com",
    age: 54
  },
  {
    id: 98,
    firstName: "Joela",
    lastName: "Douthwaite",
    email: "jdouthwaite2p@rediff.com",
    age: 25
  },
  {
    id: 99,
    firstName: "Natassia",
    lastName: "Tichelaar",
    email: "ntichelaar2q@wikimedia.org",
    age: 95
  },
  {
    id: 100,
    firstName: "Clerc",
    lastName: "Wyvill",
    email: "cwyvill2r@scientificamerican.com",
    age: 23
  }
];

export const housesData = [

// BGC CONDO & RESIDENTIAL---------------------------------------------------------------------------------------------



{
  id: 1,
  type: 'Condo',
  name: 'FOR SALE | LUXURIOUS INTERIORED 4 Bedrooms with Balcony at THE SUITES, BGC',
  description:

    'Ayala Land Premier is privileged to present the glimpse into a landmark all-suite residential community, a collection of distinctive living experiences ranging from extremely rare to one-of-a-kind. Iconic single tower residentialdevelopment, 63 storeys 298 Residential Suites with Limited Edition Sky Villas.'+'Rendered in 2BR, 3BR, and 4BR configurations Rising above a section of its retail complement and effortlessly making a statement at the corner of 3rd Avenue and 28th Street, The Suites is the singular residential aspect of One Bonifacio High Street.',

  description1:'FEATURES & AMENITIES',
  description2:'Efficiency Features',
  description3:'✓ Efficiency features for common area toilet features',
  description4:'✓ Motion sensors for lights in select commons areas',
  description5:'✓ Maximized natural ventilation for common areas',
  description6:' Safety and Security Features',
  description7:'✓ Fully addressable fire detection and alarm system',
  description8:'✓ 24-hour security and building maintenance',
  description9:'✓ Proximity card elevator access for security',
  description10:'✓ 100% back-up power (common areas, Suites, Sky Villas)',
  description11:'Convenience And Services Features',
  description12:'✓ Exclusive motorcourt',
  description13:'✓ Five (5) passenger elevators',
  description14:'✓ One (1) dedicated service elevator',
  description15:'✓ Appurtenant parking slots for every Suite and Sky Villa',
  description16:'✓ Air-conditioned ground floor lobby',
  description17:'✓ Centralized sanitary disposal system',
  description18:'Direct access to the retail shops at One Bonifacio High Street',
  description19:'✓ Lap Pool',
  description20:'✓ Fully Equipped Fitness Center',
  description21:'✓ Social Hall',
  description22:'✓ Children Play Areas',
  description23:'✓ Dedicated Residential Office',
  description24:'✓ Concierge',
  description25:'An added feature at The Suites is the inclusion of a concierge service. Residents can avail many of the services included such as pick up dry cleaning, appointment scheduling, and other various value added options.',
  description26:'✓ Mail Room',


  info: 'Details:',
  info1:'Floor: 48th',
  info2:'High-end Furnitures & Appliances',
  info3:'Area: 277 square meters',
  info4:'4 Bedrooms',
  info5:'4 Toilet & Bath',
  info6:'Powder Room',
  info7:'Maids Room with Toilet & Bath',
  info8:'Spacious Living Area',
  info9:'With Balcony',
  info10:'Gourmet Kitchen',
  info11:'Utility Kitchen',
  info12:'3 Parking Space',
  info13:'With mall access',


  image: Condo8,
  imageLg: Condo8Lg,

  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,

  imageView2:Condo9Lg,
  imageView3:Condo10Lg,
  imageView4:Condo11Lg,
  imageView5:Condo12Lg,
  imageView6:Condo13Lg,
  imageView7:Condo14Lg,
 
  status: 'For Sale',
  parking: '3',
  country: 'Bonifacio Global City',
  address: 'Premiere high-end condominium in Bonifacio Global City beside Shangri-La Fort',
  bedrooms: '4',  
  bathrooms: '4',
  surface: '277  sqms',
  year: '2010',
  price: '175,000,000.00',
  agent: {
    image: Agent6,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},

{
  id: 2,
  type: 'Condo',
  name: 'Two Serendra',
  description:
    'Two Serendra situates you at the heart of Metro Manilas newest business and lifestyle destination, the Bonifacio Global City. Touted as the home of passionate minds, you will have access to the countrys premier commercial business districts, medical and educational institutions, such as the new St. Lukes Medical Center, International School Manila and The Mind Museum, soon to rise as the countrys first world-class Science museum. Step outside your door and you have the Shops at Serendra and Bonifacio High Street, where you get to savor the newest dining and shopping options. Here, you are sure to satisfy your craving for the good life. There are FOUR rise sections each with with a special story to tell. The Landscape design of Two Serendra phase III is intended to unify the different themed zones under the concept. THE WATER GARDEN. The expansive swimming pools are the centerpiece of the landscape meeting the recreational needs of the residents while at the same time providing a lush atmosphere for the whole development.',

  info: 'Details:',
  info1:'✓  Gym',
  info2:'✓ Sauna',
  info3:'✓ Open lawn',
  info4:'✓ Splash pool',
  info5:'✓ Parking space',
  info6:'✓ Jogging trail',
  info7:'✓ Forest walkway',
  info8:'✓ 24 hour Security',
  info9:'✓ Children play areas',
  info10:'✓ Resort tyle amenities',
  info11:'✓ Kiddie pool with river boat',
  info12:'✓ Tree house with hanging bridge',

  image: Condo15,
  imageLg: Condo15Lg,

  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,

  imageView2:Condo16Lg,
  imageView3:Condo17Lg,
  imageView4:Condo18Lg,
  imageView5:Condo19Lg,
  imageView6:Condo20Lg,
  imageView7:Condo21Lg,
 
  status: 'For Sale',
  parking: '1',
  country: 'Bonifacio Global City',
  address: '1634 McKinley Pkwy, Taguig, Metro Manila',
  bedrooms: '2',  
  bathrooms: '2',
  surface: '92 sqms',
  year: '2010',
  price: '25,000,000',
  agent: {
    image: Agent6,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},

{
  id: 29,
  type: 'Residential',  
  name: 'Brand New Spectacular House & Lot in Ayala Alabang Village',
  description:
    'Ayala Alabang is Manila’s most sought-after residential address south of Metro Manila. Its prime location, first-class facilities, and proximity to important community amenities provide convenience, accessibility, and ultimate exclusivity. Considered the most sought-after residential and business address south of Metro Manila, Ayala Alabang offers the perfect mix of suburban living and urban convenience. From an old mango orchard, Ayala Land transformed this quiet suburb into the thriving community that it is today, upholding its mission to enhance land and enrich the lives of Filipinos. Barangay Ayala Alabang is now Muntinlupa’s premier residential and business district, keeping the quality of life at a premium for its residents and the nearby communities. Its exclusive subdivision is replete with upscale facilities, sustainable amenities, and beautiful houses amid gently rolling terrain. The rest of the estate seamlessly combines recreational, corporate, and commercial functions in one convenient location, so that everything you need is right outside your doorstep.',


  description1:'FEATURES & AMENITIES',
  description2:'✓ Pool with large mosaic tiles and waterfall from 2nd floor',
  description3:'✓ Canopies and Eaves cladded with premium WPC cladding',
  description4:'✓ Vehicular & pedestrian gates cladded with premium weather proof bio wood',
  description5:'✓ Extra large bespoke solid narra door',
  description6:'✓ Scenic glass encased elevator',
  description7:'✓ Grand ceiling height for the entire ground floor',
  description8:'✓ Indonesian bamboo and narra ceiling treatments',
  description9:'✓ 9.5" Indonesian narra planks steps and risers',
  description10:'✓ Premium solid narra doors',
  description11:'✓ Modular cabinetry for the bedrooms',
  description12:'✓ Large cut premium Italian tiles for all key areas',
  description13:'✓ Intricate ceiling design for all key areas',
  description14:'✓ Hand built modular cabinetry with interior lights',
  description15:'✓ Quartz stone countertops for all bathrooms, kitchen countertops',
  description16:'✓ Premium polyvinyl chloride (PVC) with security screens for all window openings',
  description17:'✓ Premium cabinetry handles and door knobs (Italian)',
  description18:'✓ 3.5" thick solid narra stairs with risers complemented with lights',
  description19:'✓ Load bearing carport tiles',
  description20:'✓ Scenic roofdeck with a bar',
  description21:'✓ Carefully thought of layout to maximize the lot area!',

  info:'Details:',
  info2:'',
  info3:'',
  info4:'',
  info5:'',
  info6:'',
  info7:'',
  info8:'',
  info9:'',
  info9:'',
  info9:'',
  info9:'',
  info9:'',
  info9:'',
  info9:'',
  info9:'',
  info9:'',
  info9:'',
 

  image: U11,
  imageLg: U11Lg,

  imageView1:U11Lg,
  imageView2:U12Lg,
  imageView3:U13Lg,
  imageView4:U14Lg,
  imageView5:U15Lg,
  imageView6:U16Lg,
  imageView7:U17Lg,
  imageView8:U18Lg,
  imageView9:U19Lg,
  imageView10:U20Lg,
  imageView11:U21Lg,
  imageView12:U22Lg,
  imageView13:U23Lg,
  imageView14:U24Lg,
  imageView15:U25Lg,
  imageView16:U26Lg,
  imageView17:U27Lg,
  imageView18:U28Lg,
  imageView19:U29Lg,

  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,
  iconView7:LiveIcon7,
  

  status:'For Sale',
  parking:'2',
  country: 'Ayala Alabang Village',
  address: 'Barangay Ayala Alabang, Muntinlupa City',
  bedrooms: '5',
  bathrooms: '7',
  surface: '440 sqms',
  year: '2010',
  price: '200,000,000.00',
  agent: {
    image: Agent13,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},

{
  id: 3,
  type: 'Residential',
  name: 'Trion Towers BGC',
  description:
    'Trion Towers by Robinsons Land is located in one of the Metro’s fastest growing commercial and business districts, Bonifacio Global City This 1- hectare property is comprised of 3 towers, each of which spans 49-storeys providing residents with breath-taking views of the Metro Manila skyline whichever tower they might be in.Trion Towers is located in the country’s best urban-planned commercial and business districts, the Bonifacio Global City in Taguig. This 1-hectare development stands at the corner of 8th Avenue and McKinley Parkway which means it is just a few minutes away from the Fort Strip, Bonifacio High Street, Market! Market!, Serendra, and countless other shopping and leisure establishments scattered all over BGC.',

  info: 'Details:',

  info1:'✓ Gym',
  info2:'✓ Game Room',
  info3:'✓ Playground',
  info4:'✓ Patio Green',
  info5:'✓ Jogging Trail',
  info6:'✓ Retail Plaza',
  info7:'✓ Swimming Pool',
  info8:'✓ Function Room',
  info9:'✓ Parking space',
  info10:'✓ Semi Furnished',
  info11:'✓ Av/Theater Room',
  info12:'✓ Maids Toilet & Bath',
  info13:'✓ Landscaped Garden',

  image: Re1,
  imageLg: Re1Lg,

  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,

  imageView2:Re2Lg,
  imageView3:Re3Lg,
  imageView4:Re4Lg,
  imageView5:Re5Lg,
  imageView6:Re6Lg,
  imageView7:Re7Lg,
 
  status: 'For Sale',
  parking: '1',
  country: 'Bonifacio Global City',
  address: '8th Avenue corner, McKinley Pkwy, Taguig, 1630 Metro Manila',
  bedrooms: '3',  
  bathrooms: '3',
  surface: '87.5 sqms',
  year: '2010',
  price: '18,500,000',
  agent: {
    image: Agent6,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},

{
  id: 4,
  type: 'Residential',
  name: 'Avida Towers BGC',
  description:
    'Barely used condo unit. Located on one of the buildings highest floors, facing towards the direction of Bonifacio High Street. A stones throw away from Uptown Mall and the upcoming Metro Manila Subway BGC station. Clean title, real property taxes paid until end of 2021. Lower than fair market value for a fully furnished unit in BGC. Parking slot is very close to the lifts (about 7 meters away). Parking can be purchased separately but buyer should be a home owner in the building (either Tower 1 or 2) as per condo guidelines. Condo unit is 36.7 sqm + Parking @ 12.5 sq.m. Seller pays for the Capital Gains Tax (CGT) and brokers fee (5%). Transfer tax, documentary stamp, notarial fees and registration are to the Buyers account. Viewing requires at least 2 days advance notice. Unit number will be intimated on the day of viewing itself.',

  info: 'Details:',
  info1:'Tower 2',
  info2:'Clean Title',
  info3:'1 Parking Space',
  info4:'Furniture all IKEA',

  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,

  imageView2:Condo2Lg,
  imageView3:Condo3Lg,
  imageView4:Condo4Lg,
  imageView5:Condo5Lg,
  imageView6:Condo6Lg,
  imageView7:Condo7Lg,

  image: Condo1,
  imageLg: Condo1Lg,

  agentImage1:Agent6,
  nameAgent: 'Jeter De Jesus',

  status: 'FOR SALE',
  country: 'Bonifacio Global City',
  address: '9th Avenue, 40th Street, Taguig, Metro Manila',
  bedrooms: '1',  
  bathrooms: '1',
  parking: '1',
  surface: '36 sq. ft.',
  year: '2010',
  price: '9,500,000.00',

  agent: {
    image: Agent13,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},

{
  id: 5,
  type: 'Residential',
  name: 'One Serendra BGC',
  description:
    'One Serendra provides gracious suburban living in the city combining the comforts of larger, more thoughtfully- planned living spaces, gardens and open spaces traditionally found in subdivisions with the conveniences of access only an urban location provides. With 65% of its hectares dedicated to open space and landscaped gardens, exclusive country-club-like amenities, and unmatched access to urban necessities, One Serendra provides homeowners both a sense of unparalleled convenience as well as immediate escape truly the best of both worlds.',

  info: 'Details:',
  info1:'✓ Balcony',
  info3:'✓ 15th Floor',
  info4:'✓ Morning Sun',
  info5:'✓ Powder Room',
  info6:'✓ 1 Parking Space',
  info7:'✓ Built in Dishwasher',
  info8:'✓ No Construction Views',
  info9:'✓ Luxuriously Decorated',
  info10:'✓ Direct Facing Pools & Gardens',
  info11:'✓ Fully Furnished, Dishwasher with appliances, cutleries & Everything',
  info12:'✓ All Windows up graded gaskets for lesset noise & better sound proofing',
  info13:'✓ Newly Painted with Boysen Vertuso Wall paints that has anti bacteria and is lead free',
  info14:'✓ All Windows are all treated with Solar Gard Tints for much lesser UV head & Sun No Glare',
  
  image: Re8,
  imageLg: Re8Lg,

  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,

  imageView2:Re9Lg,
  imageView3:Re10Lg,
  imageView4:Re11Lg,
  imageView5:Re12Lg,
  imageView6:Re13Lg,
  imageView7:Re14Lg,
 
  status: 'For Sale',
  parking: '1',
  country: 'Bonifacio Global City',
  address: 'One Serendra, 11th Avenue, Taguig, Metro Manila, Philippines',
  bedrooms: '2',  
  bathrooms: '2',
  surface: '140sqm',
  year: '2010',
  price: '63,000,000.00',
  agent: {
    image: Agent6,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},

{
  id: 6,
  type: 'Residential',
  name: 'Icon Residences BGC',
  description:
    'The Icon Residences is a luxury residential condominium community that combines lush green golf course views, the silence of nature, hotel style amenities and security, with the convenience of living in a cosmopolitan business district. Bonifacio Global City is the Philippines prime urban development in the National Capital Region, home to international schools, a first class hospital, high-end shopping malls, unrivaled dining experiences, parks, jogging tracks and high rise office spaces.',

  info: 'Details:',
  info1:'✓ Spacious',
  info2:'✓ Clean Title',
  info3:'✓ Storage Room',
  info4:'✓ 1 Parking Space',
  info5:'✓ Professionally Designed',
  info6:'✓ Cash Term or Financing',
  info7:'✓ Luxuriously Decorated',

  image: Pn1,
  imageLg: Pn1Lg,

  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,

  imageView2:Pn2Lg,
  imageView3:Pn3Lg,
  imageView4:Pn4Lg,
  imageView5:Pn5Lg,
  imageView6:Pn6Lg,

  status: 'For Sale',
  parking: '1',
  country: 'Bonifacio Global City',
  address: 'The Icon Residences, De Jesus Oval, Taguig, Metro Manila, Philippines',
  bedrooms: '1',  
  bathrooms: '1',
  surface: '61 sqm',
  year: '2010',
  price: '16,000,000.00',
  agent: {
    image: Agent6,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},

// BGC COMMERCIAL BUILDING FOR SALE-------------------------------------------------------------------------------------

{
  id: 7,
  type: 'Commercial',
  name: 'iDORM Uptown',
  description:
    'iDorm Uptown BGC is a professional dormitory along Kalayaan Ave. at short walking distance from Uptown BGC and just one ride away from Makati CBD.',
  info: 'Inclusion:',
  info1:'✓ Free Wi-Fi',
  info2:'✓ Open Closet',
  info3:'✓ Parking Space',
  info5:'✓ Fully Furnished',
  info4:'✓ Bed w/ mattress',
  info6:'✓ Keycard Door Access',
  info7:'✓ Air Conditioning Unit',
  info8:'✓ 24/7 Security and CCTV',
  info9:'✓ LED Television with TV Plus',
  info10:'✓ Mini Fridge & Electric Kettle',
  info11:'✓ Ensuite toilet & bath with bidet & hot water',
  image: Bgc1,
  imageLg: Bgc1Lg,

  imageView2:Bgc2Lg,
  imageView3:Bgc3Lg,
  imageView4:Bgc4Lg,
  imageView5:Bgc5Lg,
  imageView6:Bgc6Lg,
  imageView7:Bgc7Lg,

  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,
  
  status: 'For Sale',
  parking : '✓',
  country: 'Bonifacio Global City',
  address: '74 Kalayaan Ave. Sitio 1. Barangay West Rembo Kalayaan Ave. Makati City',
  bedrooms: '105 Units',
  bathrooms: '',
  surface: '3,560 sqm',
  year: '2010',
  price: '380,000,000.00',
  agent: {
    image: Agent13,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},


// MAKATI DATA-------------------------------------------------------------------------------------

{
  id: 8,
  type: 'Residential',
  name: 'PROSCENIUM at Rockwell',
  description:
    'iProscenium is at the center of it all. Its towers stand near surrounding business districts and commercial centers in Makati and other major cities in Metro Manila. The presence of shops and boutiques entices members of the community to stay in the Rockwell area for all their fast-moving lifestyle needs.',

  info: 'Details:',
  info1:'✓ 2 Parking Space',
  info2:'✓ Asking Price: 280k/monthly',
  info3:'✓ Maids room with toilet & bath',

  image: Ma1,
  imageLg: Ma1Lg,

  imageView2:Ma2Lg,
  imageView3:Ma3Lg,
  imageView4:Ma4Lg,
  imageView5:Ma5Lg,
  imageView6:Ma6Lg,
  imageView7:Ma7Lg,


  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,
  
  status:'For Sale',
  parking: '2',
  country: 'Makati City',
  address: 'Proscenium at Rockwell, Estrella, Rockwell Center, Makati, Metro Manila, Philippines',
  bedrooms: '3',
  bathrooms: '4',
  surface: '224 sqm',
  year: '2010',
  price: '80,000,000.00',
  agent: {
    image: Agent13,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},

{
  id: 9,
  type: 'Residential',
  name: 'Two Roxas Triangle',
  description:
    'Two Roxas Triangle is a high-rise residential condominium project in Makati City, carrying the Ayala Land Premier brand. The development promises to deliver an exclusive and distinctive living experience Has 52-storey residential unit will be one of Ayala Land Premiers most iconic residential condominium buildings and will help foster the company as one of the most high-end brands in the country',

  info: 'Details:',
  info1:'* House Details *',
  info2:'✓ 1 Powder Room',
  info3:'✓ Semi Furnished',
  info4:'✓ 1 Storage Room',
  info5:'✓ 3 Parking Space',
  info6:'✓ 1 Utility Kitchen',
  info7:'✓ 1 Gourment Kitchen',
  info8:'✓ 1 Maids Room with Toilet & Bath',

  info9:'* One Roxas Triangle and Two Roxas Triangle *',
  info10:'✓ Pool',
  info11:'✓ Salon',
  info12:'✓ Storage',
  info13:'✓ Helipad',
  info14:'✓ Mail Room',
  info15:'✓ Play room',
  info16:'✓ Game room',
  info17:'✓ Motorcourt',
  info18:'✓ Squash court',
  info19:'✓ Function room',
  info20:'✓ Fitness center',
  info21:'✓ Car washing bay',
  info22:'✓ Shared Amenities',
  info23:'✓ Outdoor play area',
  info24:'✓ Engineering office',
  info25:'✓ Administration office',
  info26:'✓ Lobby lounge and concierge',
  info27:'✓ Indoor multi-purpose court',
  info28:'✓ Outdoor multi-purpose court',
  info29:'✓ Drivers lounge and quarters',
 

  image: Ma9,
  imageLg: Ma9Lg,

  imageView2:Ma10Lg,
  imageView3:Ma11Lg,
  imageView4:Ma12Lg,
  imageView5:Ma13Lg,
  imageView6:Ma14Lg,



  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,
  
  status:'For Sale',
  parking:'3',
  country: 'Makati City',
  address: 'Two Roxas Triangle, Cruzada Street, Makati, Metro Manila, Philippines',
  bedrooms: '3',
  bathrooms: '3',
  surface: '304 sqm',
  year: '2010',
  price: '125,000,000.00',
  agent: {
    image: Agent13,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},

{
  id: 10,
  type: 'Residential',
  name: 'Bel Air 3 Village',
  description:
    'Bel-Air refers to both a private subdivision and gated community, and a barangay in Makati, Philippines. To the north, the village itself is bound by Neptune, Anza, Orion, Mercedes, and Amapola Streets, Estrella Street on the northeast, Epifanio de los Santos Avenue on the southeast, Jupiter Street on the southwest, and Nicanor Garcia Street (formerly Reposo) on the northwest. It encompasses a total land area of 787,234 square meters (8,473,720 sq ft) and is roughly shaped like a tobacco pipe. Bel-Air Village was developed in four phases, and consists of 950 lots, thirty-two streets and two well-developed parks in Phases II and III, each with covered badminton/basketball courts. Makati Avenue separates Phase II from the rest of the subdivision. The village is managed by the Bel-Air Village Association (BAVA), and comprises only a portion of Barangay Bel-Air, which now includes Ayala North, Gil Puyat Avenue (Buendia Avenue), the Ayala Triangle, and the entire Salcedo Village.',

  info: 'Details:',
  info1:'✓ Lanai',
  info2:'✓ Attic Room',
  info3:'✓ Powder Room',
  info4:'✓ With Balcony',
  info5:'✓ Swimming Pool',
  info6:'✓ 2 Living Area',
  info7:'✓ Walk in Closet',
  info8:'✓ Receiving Area',
  info9:'✓ Driver Quarter',
  info10:'✓ Gourmet Kitchen',
  info11:'✓ Utility Kitchen',
  info12:'✓ 7 Parking Space',
  info13:'✓ Maids Room with Toilet & Bath',


  image: Ma15,
  imageLg: Ma15Lg,

  imageView2:Ma16Lg,
  imageView3:Ma17Lg,
  imageView4:Ma18Lg,
  imageView5:Ma19Lg,
  imageView6:Ma20Lg,
  imageView7:Ma21Lg,
  imageView8:Ma22Lg,
  imageView9:Ma23Lg,
  imageView10:Ma24Lg,
  imageView11:Ma25Lg,  
  imageView12:Ma26Lg,
  imageView13:Ma27Lg,
  imageView14:Ma28Lg,
  imageView15:Ma29Lg,
  imageView16:Ma30Lg,


  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,
  
  status: 'For Sale',
  parking: '7',
  country: 'Makati City',
  address: 'Bel-Air, Makati, Metro Manila, Philippines',
  bedrooms: '3',
  bathrooms: '3',
  surface: '525 sqm',
  year: '2010',
  price: '230,000,000.00',
  agent: {
    image: Agent13,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},

{
  id: 11,
  type: 'Residential',
  name: 'Greend Meadows Village',
  description:
    'Developed by the Ortigas & Company Limited Partnership, Greenmeadows Subdivision is a high-end 72 hectare property offering premier lots for sale in Quezon City and near the boundary of Pasig City. Offering 24 hour security, the property management keeps its residents safe with a state of the art security measure called the Integrated Digital Access Control and Surveillance Security System. The exclusive property also offers a variety of well- maintained amenities, which include a clubhouse, a swimming pool, a playground, a covered basketball court, covered tennis courts, open basketball courts ,open tennis courts, function rooms, and cabanas.',

  info: 'Details:',
  info1:'✓ Playground',
  info2:'✓ Powder Room',
  info3:'✓ Function Room',
  info4:'✓ Drivers Quarter',
  info5:'✓ 4 Parking Space',
  info6:'✓ 700 sqm+ floor area',
  info7:'✓ Swimming Pool & Garden',
  info8:'✓ Maids Room with Toilet & Bath',


  image: Pa1,
  imageLg: Pa1Lg,

  imageView2:Pa2Lg,
  imageView3:Pa3Lg,
  

  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,
  
  status:'For Sale',
  parking:'4',
  country: 'Pasig City',
  address: 'Green Meadows Avenue, Pasig, Metro Manila, Philippines',
  bedrooms: '6',
  bathrooms: '6',
  surface: '1033 sqm',
  year: '2010',
  price: '250,000,000.00',
  agent: {
    image: Agent13,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},

{
  id: 12,
  type: 'Residential',
  name: 'Greenwoods Executive Village',
  description:
    'Greenwoods Executive Village “Prime living as it should be”. Here’s your chance to be part of an exclusive community at a reasonable price! Greenwoods Executive Village provides you with the modern convenience of a modern community with facilities to make your life easier. Now you can take an early morning jog, dinner with the whole family, shoot the hoops with your kids at the court, and have a sound sleep knowing that everything you need is just within your reach.',

  info: 'Details:',
  info1:'✓ 2 Parking Space',
  info2:'✓ tempered glasses',
  info3:'✓ Cash / Bank Loan',
  info4:'✓ Spacious living area',
  info5:'✓ Near park/playground',
  info6:'✓ Indoor & outdoor garden',
  info7:'✓ High-end Bathroom fixtures',
  info8:'✓ Balcony with artificial grass',
  info9:'✓ Modern Tropical Inspired House High ceiling',
  info10:'✓ Maids Room with Toilet & Bath',
  info11:'✓ Modern kitchen with granite countertop built in stove and exhaust system',



  image: Pa4,
  imageLg: Pa4Lg,

  imageView2:Pa5Lg,
  imageView3:Pa6Lg,
  imageView4:Pa7Lg,
  imageView5:Pa8Lg,
  imageView6:Pa9Lg,
  imageView7:Pa10Lg,
  imageView8:Pa11Lg,
  imageView9:Pa12Lg,

  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,
  
  status:'For Sale',
  parking:'2',
  country: 'Pasig City',
  address: 'Greenwoods Executive Village - Pasig Gate, Greenwoods Avenue, Pasig, Metro Manila, Philippines',
  bedrooms: '5',
  bathrooms: '5',
  surface: '260 sqm',
  year: '2010',
  price: '17,800,000.00',
  agent: {
    image: Agent13,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},

{
  id: 13,
  type: 'Residential',
  name: 'Greenwoods Executive Village',
  description:
    'Greenwoods Executive Village “Prime living as it should be”. Here’s your chance to be part of an exclusive community at a reasonable price! Greenwoods Executive Village provides you with the modern convenience of a modern community with facilities to make your life easier. Now you can take an early morning jog, dinner with the whole family, shoot the hoops with your kids at the court, and have a sound sleep knowing that everything you need is just within your reach.',

  info: 'Details:',
  info1:'✓ 2 Parking Space',
  info2:'✓ tempered glasses',
  info3:'✓ Cash / Bank Loan',
  info4:'✓ Spacious living area',
  info5:'✓ Near park/playground',
  info6:'✓ Friendly Neighborhood',
  info7:'✓ Indoor & outdoor garden',
  info8:'✓ High-end Bathroom fixtures',
  info9:'✓ Dirty Kitchen / Laundry area',
  info10:'✓ Balcony with artificial grass',
  info11:'✓ Maids Room with Toilet & Bath',
  info12:'✓ Modern Tropical Inspired House High ceiling',
  info13:'✓ Modern kitchen with granite countertop built in stove and exhaust system',


  image: Pa13,
  imageLg: Pa13Lg,

  imageView2:Pa14Lg,
  imageView3:Pa15Lg,
  imageView4:Pa16Lg,
  imageView5:Pa17Lg,
  imageView6:Pa18Lg,
  imageView7:Pa19Lg,
  imageView8:Pa20Lg,
  imageView9:Pa21Lg,

  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,
  
  
  status:'For Sale',
  parking:'2',
  country: 'Pasig City',
  address: 'Greenwoods Executive Village - Pasig Gate, Greenwoods Avenue, Pasig, Metro Manila, Philippines',
  bedrooms: '5',
  bathrooms: '5',
  surface: '300 sqm',
  year: '2010',
  price: '22,500,000.00',
  agent: {
    image: Agent13,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},

{
  id: 14,
  type: 'Residential',
  name: 'Townhouse at Kapitolyo',
  description:
    'A townhouse, townhome, town house, or town home, is a type of terraced housing. A modern townhouse is often one with a small footprint on multiple floors. In a different British usage, the term originally referred to any type of city residence of someone whose main or largest residence was a country house.',

  info:'Details:',
  info1:'✓ Semi-Furnished',
  info2:'✓ Rooftop Terrace',
  info3:'✓ Complete gated townhouse',
  info4:'✓ 4 Floors Excluding Parking',
  info5:'✓ Maids Room with Toilet & Bath',

  image: Pa22,
  imageLg: Pa22Lg,

  imageView2:Pa23Lg,
  imageView3:Pa24Lg,
  imageView4:Pa25Lg,
  imageView5:Pa26Lg,
  imageView6:Pa27Lg,
  imageView7:Pa28Lg,
  imageView8:Pa29Lg,

  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,
  
  status:'For Sale',
  parking:'1',
  country: 'Pasig City',
  address: 'Townhouse at Kapitolyo, Pasig City',
  bedrooms: '4',
  bathrooms: '3',
  surface: '191 sqm',
  year: '2010',
  price: '18,000,000.00',
  agent: {
    image: Agent13,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},

// BATANGAS DATA-------------------------------------------------------------------------------------

{
  id: 15,
  type: 'Residential',
  name: 'Ligpo Island',
  description:
    'A townhouse, townhome, town house, or town home, is a type of terraced housing. A modern townhouse is often one with a small footprint on multiple floors. In a different British usage, the term originally referred to any type of city residence of someone whose main or largest residence was a country house.',

  info:'Details:',
  info1:'✓ Spacious Living Room',
  info2:'✓ Swimming Pool',
 
  image: Ba2,
  imageLg: Ba2Lg,

  imageView2:Ba1Lg,
  imageView3:Ba3Lg,
  imageView4:Ba4Lg,
  imageView5:Ba5Lg,
  imageView6:Ba6Lg,
  imageView7:Ba7Lg,
  imageView8:Ba8Lg,
  imageView9:Ba9Lg,
  imageView10:Ba100Lg,

  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,

  status:'For Sale',
  parking:'0',
  country: 'Batangas City',
  address: 'Ligpo Island, Batangas, Philippines',
  bedrooms: '4',
  bathrooms: '5',
  surface: '4000 sqm',
  year: '2010',
  price: '150,000,000.00',
  agent: {
    image: Agent13,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},

{
  id: 16,
  type: 'Commercial',
  name: 'Porto Laiya',
  description:
    'Commercial Building',

  info:'Details:',
  
  info1:'✓ EARNING POTENTIAL',
  info2:'✓ ‼️Php 30,000 to 70,000 PER DAY‼️', 
  info3:'✓ Clean title', 

  info4:'✓ Lot Area: 626 sqm', 
  info5:'✓ Floor Area: 390 sqm', 
  info6:'✓ 5 Bedrooms with Toilet & Bath', 
  info7:'✓ Entertainment area 1', 
  info8:'✓ Round multi purpose sofa bed in common area', 
  info9:'✓ Karaoke', 
  info10:'✓ Entertainment area 2', 
  info11:'✓ Lounging sofa set', 
  info12:'✓ Entertainment area 3 (2nd floor)', 
  info13:'✓ Sala set', 
  info14:'✓ Dart', 
  info15:'✓ Billiards area', 
  info16:'✓ Billiards table & sticks', 
  info17:'✓ Bar area with faucet', 
  info18:'✓ Built in Nook with granite top',
 
  image: Ba11,
  imageLg: Ba11Lg,

  imageView2:Ba12Lg,
  imageView3:Ba13Lg,
  imageView4:Ba14Lg,
  imageView5:Ba15Lg,
  imageView6:Ba16Lg,
  imageView7:Ba17Lg,
  imageView8:Ba18Lg,
 

  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,

  status:'For Sale',
  parking:'✓',
  country: 'Batangas City',
  address: 'Porto Laiya Residential Beach Subdivison, San Juan, Batangas, Philippines',
  bedrooms: '5',
  bathrooms: '5',
  surface: '692 sqm',
  year: '2010',
  price: '60,000,000.00',
  agent: {
    image: Agent13,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},

{
  id: 17,
  type: 'Commercial',
  name: 'Porto Laiya',
  description:
    'Tali beach, Nasugbu Batangas is an exclusive residential resort that is home to 7 coves and 1 Cliff. The nearest cove is only a 5 minute walk away.3 Hours Drive from Manila',

  info:'Details:',
  info1:'✓ Spacious Living Area',
  info2:'✓ Maids Room with Toilet & Bath',
  info3:'✓ Drivers Quarter',
  info4:'✓ Swimming Pool',
  info5:'✓ Parking Space', 
  info6:'✓ Clean title', 

  image: Ba19,
  imageLg: Ba19Lg,

  imageView2:Ba20Lg,
  imageView3:Ba21Lg,

  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,

  status:'For Sale',
  parking:'✓',
  country: 'Batangas City',
  address: 'Tali Beach Drive, Nasugbu, Batangas, Philippines',
  bedrooms: '5',
  bathrooms: '5',
  surface: '600 sqm',
  year: '2010',
  price: '25,000,000.00',
  agent: {
    image: Agent13,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},

// CEBU DATA-------------------------------------------------------------------------------------

{
  id: 18,
  type: 'Residential',
  name: 'Sheraton Cebu Mactan Resort',
  description:
    'The Residences at The Sheraton Cebu Mactan Resort seamlessly combines contemporary design with tropical aesthetics and local touches to create unparalleled dwellings of exceptional beauty. The world-renowned Blink Design Group, which specializes in creating luxury hotels and resorts, took inspiration from the surrounding cliffs, beaches and exposed rocks in formulating the residences stylish look. Blinks thoughtful and innovative process has ensured that even the smallest details meet global standards and that living here will be a luxurious experience like no other. Merging the concepts of purity and serenity, the stylish and modern interiors of The Residences at The Sheraton Cebu Mactan Resort are defined by clean lines and luxurious materials such as natural stone, rattan, marble and wood. One-, two- and three-bedroom configurations are available to suit every couple or familys needs, and every unit offers unobstructed vistas of the spectacular ocean or the courtyard lagoon pools through large panoramic windows.',

  info:'Details:',
  info1:'✓ Refreigerator',
  info2:'✓ Air Conditioning',
  info3:'✓ Induction Cooktop and Range Hood',
  info4:'✓ Oven',
  info5:'✓ Built-in Cabinets', 
  info6:'✓ Washer & Dryer', 
  info7:'✓ Residential Lobby',
  info8:'✓ Courtyards Large Lagoon Pools',
  info9:'✓ Fully Fitted Fitness Center',
  info10:'✓ Separate Steam Rooms',
  info11:'✓ Residents Lounge',
  info12:'✓ Multi-purpose Function Room',
  info13:'✓ Kids Room',
  info14:'✓ Access to Private Beach Club',
  info15:'✓ Director of Residential Services',
  info16:'✓ Mailbox Area',
  info17:'✓ Four Dedicated Residential-tower Passenger Elevators',
  info18:'✓ Service Elevator',
  info19:'✓ Dedicated Parking',

  image: Ce1,
  imageLg: Ce1Lg,

  imageView2:Ce2Lg,
  imageView3:Ce3Lg,
  imageView4:Ce4Lg,
  imageView5:Ce5Lg,
  imageView6:Ce6Lg,
  
  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,

  status:'For Sale',
  parking:'✓',
  country: 'Cebu City',
  address: 'The Residences at Sheraton Cebu Mactan Resort, Biliran Road, Cebu City, Cebu, Philippines',
  bedrooms: '1',
  bathrooms: '1',
  surface: '600 sqm',
  year: '2010',
  price: '16,500,000.00',
  agent: {
    image: Agent13,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},

// ALABANG DATA-------------------------------------------------------------------------------------

{
  id: 19,
  type: 'Residential',
  name: 'Ayala Alabang Country Club',
  description:
    'Nestled along a quiet street in the South’s most exclusive community you find this gem of a property. Built with the beauty and greenery of Tropics in mind this home allows the calming elements of the surrounding flora enter and embrace the abode. Built to a high level of quality using premium materials that not only reflect in its finish but will stand up to the joy’s that daily life brings. Including modern features such as smart switches that can be controlled from your smart phone. The Living Space Ground Level High ceilings abound in this home with 5m to 8m heights in the Spacious living area of the home surrounded by windows on three sides. The living room transitions beautifully to the lanai right next to the pool where the beautiful and calming lapping of water can be heard from the pools infinity edge accompanied by the rustling of leaves from the lush tropical garden that surrounds the home.',

  info:'Details:',
  info1:'✓ Swimming Pool',
  info2:'✓ Spacious Living Area',
  info3:'✓ Lanai',
  info4:'✓ Gourmet Kitchen',
  info5:'✓ Utility Kitchen', 
  info6:'✓ Powder Room',
  info7:'✓ Laundry Area',
  info8:'✓ Maids Room with Toilet & Bath',
  info9:'✓ Drivers Quarter',
  info10:'✓ 3 Covered Garage Space (Gated)',
  info11:'✓ 2 Open Garage (Gated)',
  info12:'✓ 2 Open Garage Space (Main Driveway)', 

  image: Al1,
  imageLg: Al1Lg,

  imageView2:Al2Lg,
  imageView3:Al3Lg,
  imageView4:Al4Lg,
  imageView5:Al5Lg,
  imageView6:Al6Lg,
  imageView7:Al7Lg,
  imageView8:Al8Lg,
  imageView9:Al9Lg,
  imageView10:Al10Lg,

  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,

  status:'For Sale',
  parking:'✓',
  country: 'Muntinlupa City',
  address: 'Ayala Alabang Country Club, Ayala Alabang, Muntinlupa, Metro Manila, Philippines',
  bedrooms: '8',
  bathrooms: '5',
  surface: '1000 sqm',
  year: '2010',
  price: '230,000,000.00',
  agent: {
    image: Agent13,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},

{
  id: 20,
  type: 'Residential',  
  name: 'Ayala Alabang Village Townhouses',
  description:
    'Find the best offers for townhouse ayala alabang village. Versailles alabang offers townhouse model',

  info:'Details:',
  info1:'✓ Den',
  info2:'✓ Garden',
  info3:'✓ Basement',
  info4:'✓ Powder Room',
  info5:'✓ Swimming Pool',
  info6:'✓ Well Maintained',
  info7:'✓ Madrigal Street',
  info8:'✓ Along Main Gate',
  info9:'✓ 3 Parking Spaces',
  info10:'✓ Double Height Living',
  info11:'✓ 2 Gates (Front & Back',
  info12:'✓ Maids Room with Toilet & Bathroom',
  info13:'✓ Drivers Quarter with Toilet & Bathroom',

  image: Al21,
  imageLg: Al21Lg,

  imageView1:Al20Lg,
  imageView2:Al22Lg,
  imageView3:Al23Lg,
  imageView4:Al24Lg,
  imageView5:Al25Lg,
  imageView6:Al26Lg,
  imageView7:Al27Lg,
  imageView8:Al28Lg,
  imageView9:Al29Lg,
  imageView10:Al30Lg,
  imageView11:Al31Lg,
  imageView12:Al32Lg,


  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,

  status:'For Sale',
  parking:'✓',
  country: 'Muntinlupa City',
  address: 'Ayala Alabang Village Townhouses, Ayala Alabang, Muntinlupa, Metro Manila, Philippines',
  bedrooms: '4',
  bathrooms: '4',
  surface: '1,000 sqm',
  year: '2010',
  price: '280,000,000.00',
  agent: {
    image: Agent13,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},

{
  id: 21,
  type: 'Residential',  
  name: 'Sonria Condominium',
  description:
    'SONRIA was developed by Geo Estate, a premier Condo project located at Muntinlupa City which offers elegant and expansive living spaces located at the prestigious heart of Ayala Alabang.',

  info:'Details:',
  info1:'✓ Shoes Cabinets',
  info2:'✓ Fully Furnished',
  info3:'✓ 9th Floor Facing Alabang Zapote Road',

  image: Al17,
  imageLg: Al17Lg,

  imageView1:Al16Lg,
  imageView2:Al18Lg,
  imageView3:Al19Lg,
  imageView4:Al17Lg,


  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,

  status:'For Sale',
  parking:'3',
  country: 'Muntinlupa City',
  address: 'Sonria Condominium, Ayala Alabang, Muntinlupa City, Metro Manila, Philippines',
  bedrooms: '4',
  bathrooms: '4',
  surface: '82 sqm',
  year: '2010',
  price: '20,000,000.00',
  agent: {
    image: Agent13,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},

// QUEZON CITY DATA-------------------------------------------------------------------------------------

{
  id: 22,
  type: 'Residential',  
  name: 'Hotel & Resort at Tanay Rizal',
  description:
    'SLoyola Grand Villas Subdivision A secured gated first class subdivision.',

  info:'Details:',
  info1:'✓ Jacuzzi',
  info2:'✓ Huge Garden',
  info3:'✓ Theater Room',
  info5:'Drivers Quarter',
  info6:'✓ 1 Powder Room',
  info7:'✓ Swimming Pool',
  info8:'✓ Fully Furnished',
  info9:'✓ 15 Parking Space',
  info10:'Spacious Living Room',
  info11:'Maids room with Toilet & Bath',
  info12:'✓ 9th Floor Facing Alabang Zapote Road',

  image: Qe1,
  imageLg: Qe1Lg,

  imageView1:Qe2Lg,
  imageView2:Qe3Lg,
  imageView3:Qe4Lg,
  imageView4:Qe5Lg,
  imageView5:Qe6Lg,
  imageView6:Qe7Lg,
  imageView7:Qe8Lg,
  imageView8:Qe9Lg,
  imageView9:Qe10Lg,

  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,

  status:'For Sale',
  parking:'15',
  country: 'Quezon City',
  address: 'Loyola Grand Villas Subdivision, Kaliraya Road, Quezon City, Metro Manila, Philippines',
  bedrooms: '9',
  bathrooms: '9',
  surface: '10,000 sqm',
  year: '2010',
  price: '250,000,000.00',
  agent: {
    image: Agent13,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},


// TANAY CITY DATA------------------------------------------------------


{
  id: 23,
  type: 'Residential',  
  name: 'Luxury House and Lot',
  description:
    ' Grand Villas Subdivision a first class subdivision.',

  info:'Details:',
  info1:'✓ Jacuzzi',
  info2:'✓ Huge Garden',
  info3:'✓ Theater Room',
  info5:'Drivers Quarter',
  info6:'✓ 1 Powder Room',
  info7:'✓ Swimming Pool',
  info8:'✓ Fully Furnished',
  info9:'✓ 15 Parking Space',
  info10:'Spacious Living Room',
  info11:'Maids room with Toilet & Bath',
  info12:'✓ 9th Floor Facing Alabang Zapote Road',

  image: Ta1,
  imageLg: Ta1Lg,

  imageView1:Ta2Lg,
  imageView2:Ta3Lg,
  imageView3:Ta4Lg,
  imageView4:Ta5Lg,
  imageView5:Ta6Lg,
  imageView6:Ta7Lg,
  imageView7:Ta8Lg,
  imageView8:Ta9Lg,
  imageView9:Ta10Lg,
  imageView10:Ta11Lg,
  imageView11:Ta12Lg,
  imageView12:Ta13Lg,
  imageView13:Ta14Lg,

  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,

  status:'For Sale',
  parking:'8',
  country: 'Las Piñas City',
  address: 'Portofino Heights | Luxury House and Lot in Daang Hari, Vista Alabang, Daang Hari Road, Almanza Dos, Las Piñas, Metro Manila, Philippines',
  bedrooms: '9',
  bathrooms: '9',
  surface: '370 sqm',
  year: '2010',
  price: '45,000,000.00',
  agent: {
    image: Agent13,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},


// LAS PIÑAS CITY DATA------------------------------------------------------


{
  id: 24,
  type: 'Commercial',  
  name: 'Loyola Grand Villas',
  description:
    'SLoyola Grand Villas Subdivision A secured gated first class subdivision.',

  info:'Details:',
  info1:'✓ Jacuzzi',
  info2:'✓ Huge Garden',
  info3:'✓ Theater Room',
  info5:'Drivers Quarter',
  info6:'✓ 1 Powder Room',
  info7:'✓ Swimming Pool',
  info8:'✓ Fully Furnished',
  info9:'✓ 15 Parking Space',
  info10:'Spacious Living Room',
  info11:'Maids room with Toilet & Bath',
  info12:'✓ 9th Floor Facing Alabang Zapote Road',

  image: La1,
  imageLg: La1Lg,

  imageView1:La2Lg,
  imageView2:La3Lg,
  imageView3:La4Lg,
  imageView4:La5Lg,
  imageView5:La6Lg,
  imageView6:La7Lg,
  imageView7:La8Lg,

  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,

  status:'For Sale',
  parking:'✓',
  country: 'Tanay City',
  address: 'Loyola Grand Villas Subdivision, Kaliraya Road, Quezon City, Metro Manila, Philippines',
  bedrooms: '9',
  bathrooms: '9',
  surface: '10,000 sqm',
  year: '2010',
  price: '185,000,000.00',
  agent: {
    image: Agent13,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},


// FOR RENT DATA------------------------------------------------------


{
  id: 25,
  type: 'For Rent',  
  name: 'Two Maridien',
  description:
    'Two Maridien is the second tower of the two-tower project in Bonifacio High Street. Each of the two towers has something to offer its unit owners. For One Meridian, it is the splendid views of the central park right in front of the building. Meanwhile, Two Meridian is developed to attract world-class city dwellers. It provides a relaxing enclave that brings together convenience, comfort, and luxury. The fact that this residential condominium is also located within The Fort’s BGC area helps to create that vibe. It is a haven for those who combine life, work, and play in one.',

  info:'Details:',

  info2:'✓ With balcon',
  info5:'✓ Gym',
  info6:'✓ Lap Pool',
  info9:'✓ Playground',
  info7:'✓ Retail Area',
  info8:'✓ Kiddie Pool',
  info10:'✓ Pets Allowed',
  info11:'✓ Swimming Pool',
  info12:'✓ Function Rooms',
  info1:'✓ Fully Furnished',
  info13:'✓ 24-hour Security',
  info3:'✓ View: high Street',


  image: Two,
  imageLg: TwoLg,

  imageView1:Two2Lg,
  imageView2:Two3Lg,
  imageView3:Two4Lg,
  imageView4:Two5Lg,
  imageView5:Two6Lg,
  imageView6:Two7Lg,
  imageView7:Two8Lg,
  imageView8:Two9Lg,
  imageView9:Two10Lg,
  imageView10:Two11Lg,

  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,

  status:'For Rent',
  parking:'1',
  country: 'Taguig City',
  address: '27th Street, Taguig, Metro Manila, Philippines',
  bedrooms: '1',
  bathrooms: '2',
  surface: '69 sqms',
  year: '2010',
  price: '69,000.00',
  agent: {
    image: Agent13,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},

{
  id: 26,
  type: 'For Rent',  
  name: 'Horizon Homes',
  description:
    'Designed to surpass expectations, the Horizon Homes at Shangri-La at the Fort, Manila, are a collection of distinct homes that allow a privileged few to enjoy city living with the rare luxury of space and one of the breathtaking skyscraper views around.',

  info:'Details:',
  info2:'✓ Semi-furnished',
  info3:'✓ Gourmet Kitchen',
  info4:'✓ Utility Kitchen',
  info5:'✓ 24 hour security',
  info6:'✓ Personal conceirge',
  info7:'✓ 2 glass-backed squash courts',
  info8:'✓ Maids room with Toilet & Bath',
  info9:'✓ 2 covered outdoor tennis courts',
  info10:'✓ NBA-grade indoor basketball court',
  info11:'✓ View: Unobstracted, golf course view & Manila Bay',
  info12:'✓ Spacious gym with over 90 units of professional equipment',
  info13:'✓ 1,000-square-metre indoor playground for children of all ages',
  info14:'✓ 25-metre outdoor lap pool and hydrotherapy pool with special jets',
  info15:'✓ Various sports and therapeutic massage treatments available at physiotherapy rooms',
  info16:'✓  Spacious exercise studios for yoga, Pilates, spinning, boxing and mixed martial arts',
  info17:'✓ Locker and changing rooms with sauna room, steam room and cold plunge and vitality pools',
  info18:'✓ Exclusive recreational facilities and covenient access to Shangrila-hotel and Kerry Sports facilities',



  image: Ho1,
  imageLg: H1Lg,

  imageView1:H2Lg,
  imageView2:H3Lg,
  imageView3:H4Lg,
  imageView4:H5Lg,
  imageView5:H6Lg,
  imageView6:H7Lg,
  imageView7:H8Lg,
  imageView8:H9Lg,
  imageView9:H10Lg,
  imageView10:H11Lg,
  imageView11:H12Lg,
  imageView12:H13Lg,

  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,

  status:'For Rent',
  parking:'2',
  country: 'Bonifacio Global City',
  address: '30th Street, corner 5th Avenue, Bonifacio Global City, Taguig, 1634 Metro Manila, Philippines, 30th St, Taguig, 1634 Kalakhang Maynila',
  bedrooms: '3',
  bathrooms: '3',
  surface: '250 sqms',
  year: '2010',
  price: '360,000.00',
  agent: {
    image: Agent13,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},

{
  id: 27,
  type: 'For Rent',  
  name: 'Pacific Plaza Tower',
  description:
    'The Pacific Plaza Towers are high-end residential condominium skyscrapers located across One McKinley Place in Bonifacio Global City, Philippines. The twin 53-storey buildings, the North Tower and South Tower, were completed in 2001 and rises to 179 metres (587 feet) from the ground to its architectural top. They are currently the 20th and 21st-tallest buildings in the country and Metro Manila as well.',

  info:'Details:',
  info2:'✓ Semi-furnished',
  info3:'✓ Gourmet Kitchen',
  info4:'✓ Utility Kitchen',
  info5:'✓ 24 hour security',
  info6:'✓ Personal conceirge',
  info7:'✓ 2 glass-backed squash courts',
  info8:'✓ Maids room with Toilet & Bath',
  info9:'✓ 2 covered outdoor tennis courts',
  info10:'✓ NBA-grade indoor basketball court',
  info11:'✓ View: Unobstracted, golf course view & Manila Bay',
  info12:'✓ Spacious gym with over 90 units of professional equipment',
  info13:'✓ 1,000-square-metre indoor playground for children of all ages',
  info14:'✓ 25-metre outdoor lap pool and hydrotherapy pool with special jets',
  info15:'✓ Various sports and therapeutic massage treatments available at physiotherapy rooms',
  info16:'✓  Spacious exercise studios for yoga, Pilates, spinning, boxing and mixed martial arts',
  info17:'✓ Locker and changing rooms with sauna room, steam room and cold plunge and vitality pools',
  info18:'✓ Exclusive recreational facilities and covenient access to Shangrila-hotel and Kerry Sports facilities',



  image: Ii1,
  imageLg: I1Lg,

  imageView1:I2Lg,
  imageView2:I3Lg,
  imageView3:I4Lg,
  imageView4:I5Lg,
  imageView5:I6Lg,
  imageView6:I7Lg,

  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,

  status:'For Rent',
  parking:'2',
  country: 'Bonifacio Global City',
  address: '26th Street and 25th Street, Fort Bonifacio Manila',
  bedrooms: '4',
  bathrooms: '4',
  surface: '303 sqms',
  year: '2010',
  price: '300,000.00',
  agent: {
    image: Agent13,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},

{
  id: 28,
  type: 'For Rent',  
  name: 'The Infinity Tower',
  description:
    'The Pacific Plaza Towers are high-end residential condominium skyscrapers located across One McKinley Place in Bonifacio Global City, Philippines. The twin 53-storey buildings, the North Tower and South Tower, were completed in 2001 and rises to 179 metres (587 feet) from the ground to its architectural top. They are currently the 20th and 21st-tallest buildings in the country and Metro Manila as well.',

  info:'Details:',
  info2:'✓ View: Golf course',
  info3:'✓ Gourmet Kitchen',
  info4:'✓ Well maintained unit',
  info5:'✓ Function Room',
  info6:'✓ Parking Spaces',
  info7:'✓ 24/7 Security',
  info8:'✓ Swimming Pool',
 

  image: Pl1,
  imageLg: Pl1Lg,

  imageView1:Pl2Lg,
  imageView2:Pl3Lg,
  imageView3:Pl4Lg,
  imageView4:Pl5Lg,
  imageView5:Pl6Lg,
  imageView6:Pl7Lg,
  imageView7:Pl8Lg,
  imageView8:Pl9Lg,
  imageView9:Pl10Lg,
  imageView10:Pl11Lg,
  imageView11:Pl12Lg,
  imageView12:Pl13Lg,
  imageView13:Pl14Lg,
  imageView14:Pl15Lg,
  imageView15:Pl16Lg,
  imageView16:Pl17Lg,
  imageView17:Pl18Lg,
  imageView18:Pl19Lg,
  imageView19:Pl20Lg,

  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,

  status:'For Rent',
  parking:'2',
  country: 'Bonifacio Global City',
  address: '26th Street and 25th Street, Fort Bonifacio Manila',
  bedrooms: '2',
  bathrooms: '2',
  surface: '86 sqms',
  year: '2010',
  price: '70,000.00',
  agent: {
    image: Agent13,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},

{
  id: 30,
  type: 'For Lease',  
  name: 'Newly Renovated House & Lot w/ swimming Pool',
  description:
    'Forbes Park, a gated community where expats, diplomats, and some of the country  wealthiest families live. Most  Upscale and luxurious Village in Metro Manila. Its home to the Manila Polo Club, where in addition to equestrian sports, members bowl, and play tennis and squash. The prestigious Manila Golf & Country Club features Makati only 18-hole course, while upscale shops and restaurants fill the elegant San Antonio Plaza. The subdivision is divided into Forbes Park North and Forbes Park South by McKinley Road and is bounded roughly by Epifanio de los Santos Avenue to the northwest, Taguig (Fort Bonifacio which includes Bonifacio Global City) to the east, the Maricaban Creek to the south and southeast, and Acacia Avenue/Dasmariñas Village to the west.',

  description1:'FEATURES',
  description2:'✓ Two Storeys',
  description3:'✓ Floor Area: 850+ square meters',
  description4:'✓ Den & Office',
  description5:'✓ Storage Room',
  description6:'✓ Maids room and Drivers Quarters',
  description7:'✓ Kitchen cabinets are German Siematic cabinets.',
  description8:'✓ Refrigerators SubZero (U.S.) cooking appliances, dishwasher, laundry machine and dryer Miele (Germany)',
  description9:'✓ The house is currently under renovation and will be done by Mid August but we can start accepting offers and viewings.',

  info:'Details:',
  info1:'✓ Swimming Pool ',
  info2:'✓ Polo Club',
  info3:'✓ Function Room',
  info4:'✓ Basketball Court',
  info5:'✓ Badminton Court',
  info6:'✓ Gym',
  info7:'✓ 24/7 Security.',
  info8:'✓ Big Parks',
 

  image: U34,
  imageLg: U34Lg,

  imageView1:U35Lg,
  imageView2:U36Lg,
  imageView3:U37Lg,
  imageView4:U38Lg,
  imageView5:U39Lg,
  imageView6:U40Lg,

  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,

  status:'For Lease',
  parking:'8',
  country: 'North Forbes Park Village',
  address: 'Forbes Park, Makati, Metro Manila',
  bedrooms: '6',
  bathrooms: '6',
  surface: '1300 sqms',
  year: '2010',
  price: '2,000,000.00/Monthly',
  agent: {
    image: Agent13,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},


{
  id: 31,
  type: 'FOR SALE ',  
  name: 'Luxurious Modern House & Lot  in Multinational Village',
  description:
    'Forbes Park, a gated community where expats, diplomats, and some of the country  wealthiest families live. Most  Upscale and luxurious Village in Metro Manila. Its home to the Manila Polo Club, where in addition to equestrian sports, members bowl, and play tennis and squash. The prestigious Manila Golf & Country Club features Makati only 18-hole course, while upscale shops and restaurants fill the elegant San Antonio Plaza. The subdivision is divided into Forbes Park North and Forbes Park South by McKinley Road and is bounded roughly by Epifanio de los Santos Avenue to the northwest, Taguig (Fort Bonifacio which includes Bonifacio Global City) to the east, the Maricaban Creek to the south and southeast, and Acacia Avenue/Dasmariñas Village to the west.',

  description1:'FEATURES',
  description2:'✓ Modern Design Show Kitchen',
  description3:'✓ Viro Casa Kitchen cabinets',
  description4:'✓ Spacious Viro Casa Gourmet Kitchen',
  description5:'✓ All vanities and modular cabinets from Viro Casa',
  description6:'✓ Pantry room',
  description7:'✓ Playroom',
  description8:'✓ Family area',
  description9:'✓ Home office',
  description10:'✓ Spacious Home Gym',
  description11:'✓ Driver’s room with T&B',
  description12:'✓ Maid’s room with T&B',
  description13:'✓ 2 stock rooms',
  description14:'✓ Staff lounge area',
  description15:'✓ Covered Lanai',
  description16:'✓ Covered alfresco bar with open-kitchen',
  description17:'✓ Lap pool',
  description18:'✓ Shower room',
  description19:'✓ Elevated airconditioned powder room with view of the pool',
  description20:'✓ Grand stair case',
  description21:'✓ Italian tiled — All tiles are imported, no locally sourced tiles',
  description22:'✓ All Sanitary and Shower fixtures and faucets are from Germany',
  description23:'✓  Wifi data on all rooms',
  description24:'✓ 17 AC units',
  description25:'✓ 16 CCTV camera, 10kW Solar panel net metering system',
  description26:'✓ All Imported Beautiful Chandeliers',
  description27:'✓ Whole House Water purifier & softener system by Aqua Safe water purifier, no need to buy mineral water',
  description28:'✓ Complete Smeg kitchen appliances',
  description28:'✓ Ground floor with 7.5 meters ceiling height (triple ceiling height)',
  description30:'✓ Water feature at the entrance',
  description31:'✓ Huge 3 meters high main door — pivot',
  description32:'✓ Four car garage',
  description33:'✓ Alexa-capable (Smart Home)',
  
  info:'Details:',
  info1:'✓ 2 Storey with Mezzanine',
  info2:'✓ Brand New House',
  info3:'✓ Hotel type',
  info4:'✓ Fully-furnished',
  info5:'✓ Walk in closet',
  info6:'✓ Gym',
  info7:'✓ Great Hall',

  image: U41,
  imageLg: U41Lg,

  imageView1:U42Lg,
  imageView2:U43Lg,
  imageView3:U44Lg,
  imageView4:U45Lg,
  imageView5:U46Lg,
  imageView6:U47Lg,
  imageView7:U48Lg,
  imageView8:U49Lg,
  imageView9:U50Lg,
  imageView10:U51Lg,
  imageView11:U52Lg,


  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,

  status:'For Lease',
  parking:'',
  country: 'Multinational Village Village',
  address: 'Multinational Village, Metro Manila',
  bedrooms: '6',
  bathrooms: '6',
  surface: '450  sqms',
  year: '2010',
  price: '150,000,000.00/Million',
  agent: {
    image: Agent13,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},

{
  id: 32,
  type: 'FOR SALE ',  
  name: '3 Bedrooms Bi-Level Unit in Twin oaks, Shaw blvd, Mandaluyong',
  description:
    'A high-rise residential located at the center of Greenfield District. Twin Oaks Place is the very definition of a future-ready development with a state-of-the-art technological backbone. It has Fiber-to-the home (FTTH) technology that help residences enjoy the convenience of a smart automated home.',

  description1:'FEATURES',
  description2:'✓ Main lobby with front desk service / Concierge',
  description3:'✓ 24/7 Security Services',
  description4:'✓ Fire Alarm & Sprinkler System',
  description5:'✓ Provision for CCTV monitors',
  description6:'✓ Four (4) High Speed Residential',
  description7:'✓ Elevators which can accommodate up to 980kg or approximately 14 person each',
  description8:'✓  One (1) Service Elevator which can accommodate up to 1,600kg',
  description9:'✓ Laundry Services Area',
  description10:'✓ Stand-by Power Supply',
  description11:'✓ Water Storage Facilities Overhead Tank & Cistern',
  description12:'✓ Centralized sanitary disposal system',
  description13:'✓ 2 Swimming Pools',
  description14:'✓ Lanai',
  description15:'✓ Fitness Gyms',
  description16:'✓ Landscaped Gardens',
  description17:'✓ Children’s Play Area',
  description18:'✓ Function Rooms',
  description19:'✓ Entertainment Rooms',
  description20:'✓ Game Room',
  description21:'✓ Bridgeway',

  info:'Details:',
  info1:'✓ 40th',
  info2:'✓ 2 Beds,',
  info3:'✓ Hotel type',
  info4:'✓ Semi-furnished',
  info5:'✓ Basic Kitchen appliances',
  info6:'✓ Dining Set',
  info7:'✓ Sofa',

  image: U53,
  imageLg: U53Lg,

  imageView1:U54Lg,

  imageView3:U56Lg,
  imageView4:U57Lg,
  imageView5:U58Lg,
  imageView6:U59Lg,
  imageView7:U60Lg,
  imageView8:U61Lg,
  imageView9:U62Lg,
  imageView10:U63Lg,
  


  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,

  status:'For Lease',
  parking:'1',
  country: 'Shaw blvd, Mandaluyong ',
  address: 'Shaw blvd, Mandaluyong',
  bedrooms: '3',
  bathrooms: '3',
  surface: '116   sqms',
  year: '2010',
  price: '150,000,000.00/Million',
  agent: {
    image: Agent13,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},


{
  id: 31,
  type: 'FOR SALE ',  
  name: 'Spacious Brand New 1 Bedroom Unit in Uptown Parksuites, BGC',
  description:
    'Uptown Parksuites offers stylish and contemporary residential units designed for modern urban living. This modern property is a 50-storey (Tower 1) and 46-storey (tower 2 )high Full Glass Tower with a full-packed amenities located on the 5th Floor. Beneath your unit is the High-end malls. which is a very perfect property if you are looking for investment or personal use. Uptown Bonifacio is Megaworld’s premier development a 15-hectare master-planned community located in the heart of Metro Manila. it brings you everything that you need just within your reach. With its modern architecture, this project will be the new icon for modern condominium living.  Accessible in 3 major roads which are c5, kalayaan road, lawton road. In C5 road: going to ortigas, QC, eastwood just 15 mins away from you home Thru Kalayaan: going to Makati, Edsa just 5 Mins away Thru Lawton Road: Ayala ave and Newport/ Naia Terminal Airport just 10- 15mins away',

  description1:'FEATURES',
  description2:'✓ Swimming pool',
  description3:'✓ Still pool',
  description4:'✓ In-water pool lounge',
  description5:'✓ Children’s pool',
  description6:'✓ Composite wood deck',
  description7:'✓ Meditation area',
  description8:'✓ Reflexology walk',
  description9:'✓ Fitness center with spa and outdoor fitness area',
  description10:'✓ Game room',
  description11:'✓ Dry garden',
  description12:'✓ Children’s playground',
  description13:'✓ Day care center',
  description14:'✓ Picnic and hammock area',
  description15:'✓ Trellised walks',
  description16:'✓ Business center',
  description17:'✓ Laundromat',
  description18:'✓ Reading nook',
  description19:'✓ Multipurpose halls',
  description20:'✓ Lap pool',
  description21:'✓ ',
  description22:'✓  Jacuzzi beds and pool lounge',
  description23:'✓ Composite wood deck',
  description24:'✓ Clubhouse',
  description25:'✓  Day care center',
  description26:'✓ Business center',

  
  info:'Details:',
  info1:'✓ With Balcony',
  info2:'✓ Open Kitchen,',
  info3:'✓ Clubhouse',
  info4:'✓ Composite wood deck',
  info5:'✓ Jacuzzi beds and pool lounge',
  info6:'✓ Laundromat',
  info7:'✓ Corner Unit',

  image: U65,
  imageLg: U66Lg,

  imageView1:U67Lg,
  imageView2:U68Lg,
  imageView3:U69Lg,
  imageView4:U70Lg,
  imageView5:U71Lg,
  imageView6:U72Lg,
  imageView7:U73Lg,
  imageView8:U74Lg,
  imageView9:U75Lg,
  imageView10:U76Lg,
  imageView11:U78Lg,
  imageView11:U79Lg,
  imageView11:U80Lg,


  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,

  status:'For Lease',
  parking:'1',
  country: ' Bonifacio Drive, Uptown Bonifacio, Fort Bonifacio, Taguig City ',
  address: ' Bonifacio Drive, Uptown Bonifacio, Fort Bonifacio, Taguig City',
  bedrooms: '1',
  bathrooms: '1',
  surface: '65    sqms',
  year: '2010',
  price: '19,500,000.00/Million',
  agent: {
    image: Agent13,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},

{
  id: 34,
  type: 'FOR SALE ',  
  name: 'THE SUITES AT ONE BONIFACIO HIGH STREET',
  description:
    'Uptown Parksuites offers stylish and contemporary residential units designed for modern urban living. This modern property is a 50-storey (Tower 1) and 46-storey (tower 2 )high Full Glass Tower with a full-packed amenities located on the 5th Floor. Beneath your unit is the High-end malls. which is a very perfect property if you are looking for investment or personal use. Uptown Bonifacio is Megaworld’s premier development a 15-hectare master-planned community located in the heart of Metro Manila. it brings you everything that you need just within your reach. With its modern architecture, this project will be the new icon for modern condominium living.  Accessible in 3 major roads which are c5, kalayaan road, lawton road. In C5 road: going to ortigas, QC, eastwood just 15 mins away from you home Thru Kalayaan: going to Makati, Edsa just 5 Mins away Thru Lawton Road: Ayala ave and Newport/ Naia Terminal Airport just 10- 15mins away',

  description1:'FEATURES',
  description2:'✓ Motion sensors for lights in select commons areas',
  description3:'✓ Maximized natural ventilation for common areas',
  description4:'✓  Efficiency features for common area toilet features',
  description5:'✓ Fully addressable fire detection and alarm system',
  description6:'✓ 24-hour security and building maintenance',
  description7:'✓ Proximity card elevator access for security',
  description8:'✓ 100% back-up power (common areas, Suites, Sky Villas)',
  description9:'✓ Exclusive motorcourt',
  description10:'✓  Five (5) passenger elevators',
  description11:'✓ One (1) dedicated service elevator',
  description12:'✓  Appurtenant parking slots for every Suite and Sky Villa',
  description13:'✓ Air-conditioned ground floor lobby',
  description14:'✓ Centralized sanitary disposal system',
  description15:'✓ Direct access to the retail shops at One Bonifacio High Street Lap Pool',
  description16:'✓ An exclusive lap pool for residents is just one of the featured amenities of The Suites at One Bonifacio.',
  description17:'✓ Recreation',
  description18:'✓ Fully Equipped Fitness Center',
  description19:'✓ Social Hall',
  description20:'✓ Children’s Play Areas',
  description21:'✓ Dedicated Residential Admin Office',
  description22:'✓ Concierge ',
  description23:'✓ Mail Room',


  
  info:'Details:',
  info1:'✓ 1 Powder Room',
  info2:'✓ Open Kitchen,',
  info3:'✓ Wide Balcony in Living area',
  info4:'✓ Spacious Living Area',
  info5:'✓ Gourmet Kitchen',
  info6:'✓ Utility Kitchen',
  info7:'✓ Maids room w/ Toilet & Bath',

  image: U81,
  imageLg: U81Lg,

  imageView1:U82Lg,
  imageView2:U83Lg,
  imageView3:U84Lg,
  imageView4:U85Lg,
  imageView5:U86Lg,
  imageView6:U87Lg,
  imageView7:U89Lg,
  imageView8:U90Lg,
  imageView9:U91Lg,
  imageView10:U92Lg,
  imageView11:U93Lg,



  iconView1:LiveIcon1,
  iconView2:LiveIcon2,
  iconView3:LiveIcon3,
  iconView4:LiveIcon4,
  iconView5:LiveIcon5,
  iconView6:LiveIcon6,

  status:'FOR SALE',
  parking:'3',
  country: ' Taguig City ',
  address: ' The Suites at One Bonifacio. Taguig City',
  bedrooms: '3',
  bathrooms: '3',
  surface: '212 sqms',
  year: '2010',
  price: '99,000,000.00/Million',
  agent: {
    image: Agent13,
    name: 'Jeter De Jesus',
    phone: '0123 456 78910',
  },
},







];




