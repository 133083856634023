
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "./css/swip.css";

// import required modules
import { EffectCoverflow, Pagination } from "swiper";

import aw1 from '../assets/img/lindgren/t2.PNG'
import aw2 from '../assets/img/lindgren/t3.PNG'
import aw3 from '../assets/img/lindgren/t4.PNG'
import aw4 from '../assets/img/lindgren/t5.PNG'
import aw5 from '../assets/img/lindgren/t6.PNG'
import aw6 from '../assets/img/lindgren/t7.PNG'
import aw7 from '../assets/img/lindgren/t8.PNG'
import aw8 from '../assets/img/lindgren/t9.PNG'
import aw9 from '../assets/img/lindgren/t10.PNG'
import aw10 from '../assets/img/lindgren/t11.PNG'
import aw11 from '../assets/img/lindgren/t12.PNG'






export default function App() {
  return (
    <>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={true}
        modules={[EffectCoverflow]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={aw1} alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={aw2} alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={aw3} alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={aw4} alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={aw5} alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={aw6} alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={aw7} alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={aw8} alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={aw9} alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={aw10} alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={aw11} alt='' />
        </SwiperSlide>

      </Swiper>
    </>
  );
}