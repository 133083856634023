import { React, useEffect } from 'react';

import { housesData} from '../data';
import {useParams} from 'react-router-dom';
import {BiBed, BiBath, BiArea, BiCar} from 'react-icons/bi';

import "../components/css/image.css"
import "../components/css/image2.css"
import "../components/js/image.js"
import Banner from '../components/Banner';



// Import Swiper styles

import "swiper/css/pagination";
import "swiper/css/navigation";

import "../components/css/list.css";
import Footer from '../components/Footer';

import Lightbox from "../components/lightbox"

const PropertyDetails = () => {
  useEffect(() => {
    window.scrollTo(0,0);
  });
  const { id } = useParams();
  const property = housesData.find((house) => {
    return house.id === parseInt(id);
  });

  return ( <>

  <Banner />

  <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-4">
      <div className="flex flex-col max-w-screen-lg overflow-hidden bg-white border-gray-300 border rounded shadow-sm lg:flex-row sm:mx-auto mt-20 ">
        <div className="relative lg:w-1/2  ">
          <a href="#lightbox-image-1">
            <img
              src={property.imageLg}
              alt="Philippines"
              className="object-cover w-full lg:absolute h-80 lg:h-full"
            />
          </a>
        </div>
        <div className="flex flex-col justify-center p-2 bg-white lg:p-16 lg:pl-10 lg:w-1/2 ">
          <h5 className="mb-3 text-3xl font-extrabold leading-none sm:text-4xl">
            {property.name}
          </h5>
          <p className="mb-5 text-gray-800">
          
            <li className="flex items-start">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
              </svg>
                {property.address}
            </li>
         
         
          </p>
          <div className='flex gap-x-2 text-sm '>
            <div className='bg-green-500 text-white px-3 rounded-full'>{property.type}</div>
            <div className='bg-blue-500 text-white px-3 rounded-full'>{property.country}</div>
            <div className='bg-red-500 text-white px-3 rounded-full'>{property.status}</div>
          </div>

          <div className='flex gap-x-2 text-sm mt-2'>
            <div className='flex gap-x-2 items-center'>
              <BiBed className='text-2xl' />
              <div>Bedroom {property.bedrooms}</div>
            </div>
            <div className='flex gap-x-2 items-center'>
              <BiBath className='text-2xl' />
              <div>{property.bathrooms}</div>
            </div>
            <div className='flex gap-x-2 items-center'>
              <BiCar className='text-2xl' />
              <div>{property.parking}</div>
            </div>
            <div className='flex gap-x-2 items-center'>
              <BiArea className='text-2xl' />
              <div>{property.surface}</div>
            </div>
          </div>
          <div className='text-[22px] font-bold text-black-600 py-3'> Price: ₱ <u>{property.price}</u></div>
          <a href="#lightbox-image-1">
            <div className='text-[15px] font-medium text-blue-400'></div>
          </a>
        </div>
      </div>

    <div>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Lightbox/>
    </div>


    

    </div>
    <hr className='border-black my-2' />
      <p className=''></p>
      <div className='font-bold text-2xl text-left mt-8'> Description</div>
      <div className='font-sans text-[16px] py-1 text-justify'>{property.description}</div>
      {property.description1 && <div className='font-sans text-[16px] py-1 text-justify'>{property.description1}</div>}
      {property.description2 && <div className='font-sans text-[16px] py-1 text-justify'>{property.description2}</div>}
      {property.description3 && <div className='font-sans text-[16px] py-1 text-justify'>{property.description3}</div>}
      {property.description4 && <div className='font-sans text-[16px] py-1 text-justify'>{property.description4}</div>}
      {property.description5 && <div className='font-sans text-[16px] py-1 text-justify'>{property.description5}<br></br><br></br></div>}
      {property.description6 && <div className='font-sans text-[16px] py-1 text-justify'>{property.description6}</div>}
      {property.description7 && <div className='font-sans text-[16px] py-1 text-justify'>{property.description7}</div>}
      {property.description8 && <div className='font-sans text-[16px] py-1 text-justify'>{property.description8}</div>}
      {property.description9 && <div className='font-sans text-[16px] py-1 text-justify'>{property.description9}</div>}
      {property.description10 && <div className='font-sans text-[16px] py-1 text-justify'>{property.description10}<br></br><br></br></div>}
      {property.description11 && <div className='font-sans text-[16px] py-1 text-justify'>{property.description11}</div>}
      {property.description12 && <div className='font-sans text-[16px] py-1 text-justify'>{property.description12}</div>}
      {property.description13 && <div className='font-sans text-[16px] py-1 text-justify'>{property.description13}</div>}
      {property.description14 && <div className='font-sans text-[16px] py-1 text-justify'>{property.description14}</div>}
      {property.description15 && <div className='font-sans text-[16px] py-1 text-justify'>{property.description15}</div>}
      {property.description16 && <div className='font-sans text-[16px] py-1 text-justify'>{property.description16}</div>}
      {property.description17 && <div className='font-sans text-[16px] py-1 text-justify'>{property.description17}</div>}
      {property.description18 && <div className='font-sans text-[16px] py-1 text-justify'>{property.description18}<br></br><br></br></div>}
      {property.description19 && <div className='font-sans text-[16px] py-1 text-justify'>{property.description19}</div>}
      {property.description20 && <div className='font-sans text-[16px] py-1 text-justify'>{property.description20}</div>}
      {property.description21 && <div className='font-sans text-[16px] py-1 text-justify'>{property.description21}</div>}
      {property.description22 && <div className='font-sans text-[16px] py-1 text-justify'>{property.description22}</div>}
      {property.description23 && <div className='font-sans text-[16px] py-1 text-justify'>{property.description23}</div>}
      {property.description24 && <div className='font-sans text-[16px] py-1 text-justify'>{property.description24}<br></br><br></br></div>}
      {property.description25 && <div className='font-sans text-[16px] py-1 text-justify'>{property.description25}</div>}
      {property.description26 && <div className='font-sans text-[16px] py-1 text-justify'>{property.description26}</div>}

    <hr className='border-black my-2 mt-8' />


    <div className="lg:w-1/2">
          <h2 className="max-w-md mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none xl:max-w-lg mt-10">
            Property Details.
          </h2>
        
        

  </div>
    
    <div className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-2 ">
      <div className="grid gap-8 row-gap-10 sm:grid-cols-2 lg:grid-cols-4">
        <div>
          <h6 className="mb-2 font-semibold leading-5"></h6>
          <ul className="mb-4 -ml-1 space-y-2">
            <li className="flex items-start">
              <span className="mr-1">
                <svg
                  className="w-5 h-5 mt-px text-deep-purple-accent-400"
                  stroke="currentColor"
                  viewBox="0 0 52 52"
                >
                  <polygon
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    points="29 13 14 29 25 29 23 39 38 23 27 23"
                  />
                </svg>
              </span>
              {property.info1}<br></br>
     
            </li>
            <li className="flex items-start">
              <span className="mr-1">
                <svg
                  className="w-5 h-5 mt-px text-deep-purple-accent-400"
                  stroke="currentColor"
                  viewBox="0 0 52 52"
                >
                  <polygon
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    points="29 13 14 29 25 29 23 39 38 23 27 23"
                  />
                </svg>
              </span>
              {property.info5}<br></br>
            </li>
            <li className="flex items-start">
              <span className="mr-1">
                <svg
                  className="w-5 h-5 mt-px text-deep-purple-accent-400"
                  stroke="currentColor"
                  viewBox="0 0 52 52"
                >
                  <polygon
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    points="29 13 14 29 25 29 23 39 38 23 27 23"
                  />
                </svg>
              </span>
              {property.info9}<br></br>
            </li>
            <li className="flex items-start">
              <span className="mr-1">
                <svg
                  className="w-5 h-5 mt-px text-deep-purple-accent-400"
                  stroke="currentColor"
                  viewBox="0 0 52 52"
                >
                  <polygon
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    points="29 13 14 29 25 29 23 39 38 23 27 23"
                  />
                </svg>
              </span>
              {property.info13}<br></br>
  
            </li>
          </ul>
        </div>
        <div>
          <h6 className="mb-2 font-semibold leading-5"></h6>
          <ul className="mb-4 -ml-1 space-y-2">
            <li className="flex items-start">
              <span className="mr-1">
                <svg
                  className="w-5 h-5 mt-px text-deep-purple-accent-400"
                  stroke="currentColor"
                  viewBox="0 0 52 52"
                >
                  <polygon
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    points="29 13 14 29 25 29 23 39 38 23 27 23"
                  />
                </svg>
              </span>
              {property.info2}<br></br>
            </li>
            <li className="flex items-start">
              <span className="mr-1">
                <svg
                  className="w-5 h-5 mt-px text-deep-purple-accent-400"
                  stroke="currentColor"
                  viewBox="0 0 52 52"
                >
                  <polygon
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    points="29 13 14 29 25 29 23 39 38 23 27 23"
                  />
                </svg>
              </span>
              {property.info6}<br></br>
            </li>
            <li className="flex items-start">
              <span className="mr-1">
                <svg
                  className="w-5 h-5 mt-px text-deep-purple-accent-400"
                  stroke="currentColor"
                  viewBox="0 0 52 52"
                >
                  <polygon
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    points="29 13 14 29 25 29 23 39 38 23 27 23"
                  />
                </svg>
              </span>
              {property.info10}<br></br>
            </li>
            <li className="flex items-start">
              <span className="mr-1">
                <svg
                  className="w-5 h-5 mt-px text-deep-purple-accent-400"
                  stroke="currentColor"
                  viewBox="0 0 52 52"
                >
                  <polygon
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    points="29 13 14 29 25 29 23 39 38 23 27 23"
                  />
                </svg>
              </span>
              {property.info14}<br></br>
            </li>
          </ul>
        </div>
        <div>
          <h6 className="mb-2 font-semibold leading-5"></h6>
          <ul className="mb-4 -ml-1 space-y-2">
            <li className="flex items-start">
              <span className="mr-1">
                <svg
                  className="w-5 h-5 mt-px text-deep-purple-accent-400"
                  stroke="currentColor"
                  viewBox="0 0 52 52"
                >
                  <polygon
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    points="29 13 14 29 25 29 23 39 38 23 27 23"
                  />
                </svg>
              </span>
              {property.info3}<br></br>
            </li>
            <li className="flex items-start">
              <span className="mr-1">
                <svg
                  className="w-5 h-5 mt-px text-deep-purple-accent-400"
                  stroke="currentColor"
                  viewBox="0 0 52 52"
                >
                  <polygon
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    points="29 13 14 29 25 29 23 39 38 23 27 23"
                  />
                </svg>
              </span>
              {property.info7}<br></br>
            </li>
            <li className="flex items-start">
              <span className="mr-1">
                <svg
                  className="w-5 h-5 mt-px text-deep-purple-accent-400"
                  stroke="currentColor"
                  viewBox="0 0 52 52"
                >
                  <polygon
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    points="29 13 14 29 25 29 23 39 38 23 27 23"
                  />
                </svg>
              </span>
              {property.info11}<br></br>
            </li>
            <li className="flex items-start">
              <span className="mr-1">
                <svg
                  className="w-5 h-5 mt-px text-deep-purple-accent-400"
                  stroke="currentColor"
                  viewBox="0 0 52 52"
                >
                  <polygon
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    points="29 13 14 29 25 29 23 39 38 23 27 23"
                  />
                </svg>
              </span>
              {property.info15}<br></br>
            </li>
          </ul>
        </div>
        <div>
          <h6 className="mb-2 font-semibold leading-5"></h6>
          <ul className="mb-4 -ml-1 space-y-2">
            <li className="flex items-start">
              <span className="mr-1">
                <svg
                  className="w-5 h-5 mt-px text-deep-purple-accent-400"
                  stroke="currentColor"
                  viewBox="0 0 52 52"
                >
                  <polygon
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    points="29 13 14 29 25 29 23 39 38 23 27 23"
                  />
                </svg>
              </span>
              {property.info4}<br></br>
            </li>
            <li className="flex items-start">
              <span className="mr-1">
                <svg
                  className="w-5 h-5 mt-px text-deep-purple-accent-400"
                  stroke="currentColor"
                  viewBox="0 0 52 52"
                >
                  <polygon
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    points="29 13 14 29 25 29 23 39 38 23 27 23"
                  />
                </svg>
              </span>
              {property.info8}<br></br>
            </li>
            <li className="flex items-start">
              <span className="mr-1">
                <svg
                  className="w-5 h-5 mt-px text-deep-purple-accent-400"
                  stroke="currentColor"
                  viewBox="0 0 52 52"
                >
                  <polygon
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    points="29 13 14 29 25 29 23 39 38 23 27 23"
                  />
                </svg>
              </span>
              {property.info12}<br></br>
            </li>
            <li className="flex items-start">
              <span className="mr-1">
                <svg
                  className="w-5 h-5 mt-px text-deep-purple-accent-400"
                  stroke="currentColor"
                  viewBox="0 0 52 52"
                >
                  <polygon
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    points="29 13 14 29 25 29 23 39 38 23 27 23"
                  />
                </svg>
              </span>
              {property.info16}<br></br>
            </li>
          </ul>

        </div>
      </div>
    </div>
    <hr className='border-black my-2' />
</div>


<Footer />





























































































   

    </>
  );
};

export default PropertyDetails;