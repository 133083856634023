import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import { housesData, displayAgent } from '../data';
import {useParams} from 'react-router-dom';

function Lightbox() {
	// To open the lightbox change the value of the "toggler" prop.
	const [toggler, setToggler] = useState(false);
  const { id } = useParams();
  const property = housesData.find((house) => {
    return house.id === parseInt(id);
  });
	return (
		<>
			<button onClick={() => setToggler(!toggler)}>
      <div className="flex flex-wrap justify-center items-center">
  <img src={property.imageView2} alt="House Image" className="w-34 h-24 m-2" />
  <img src={property.imageView3} alt="House Image" className="w-34 h-24 m-2" />
  <img src={property.imageView4} alt="House Image" className="w-34 h-24 m-2" />
  <img src={property.imageView5} alt="House Image" className="w-34 h-24 m-2" />
  <img src={property.imageView6} alt="House Image" className="w-34 h-24 m-2" />

{property.imageView8 && (<img src={property.imageView8} alt="House Image" className="w-34 h-24 m-2 hidden" />)}
{property.imageView9 && (<img src={property.imageView9} alt="House Image" className="w-34 h-24 m-2 hidden" />)}
{property.imageView10 && (<img src={property.imageView10} alt="House Image" className="w-34 h-24 m-2 hidden" />)}
{property.imageView11 && (<img src={property.imageView11} alt="House Image" className="w-34 h-24 m-2 hidden" />)}
{property.imageView12 && (<img src={property.imageView12} alt="House Image" className="w-34 h-24 m-2 hidden" />)}
{property.imageView13 && (<img src={property.imageView13} alt="House Image" className="w-34 h-24 m-2 hidden" />)}
{property.imageView14 && (<img src={property.imageView14} alt="House Image" className="w-34 h-24 m-2 hidden" />)}
{property.imageView15 && (<img src={property.imageView15} alt="House Image" className="w-34 h-24 m-2 hidden" />)}
{property.imageView16 && (<img src={property.imageView16} alt="House Image" className="w-34 h-24 m-2 hidden" />)}
{property.imageView17 && (<img src={property.imageView17} alt="House Image" className="w-34 h-24 m-2 hidden" />)}
{property.imageView18 && (<img src={property.imageView18} alt="House Image" className="w-34 h-24 m-2 hidden" />)}
{property.imageView19 && (<img src={property.imageView19} alt="House Image" className="w-34 h-24 m-2 hidden" />)}

  



</div>

        

      
			</button>
			 <FsLightbox
        toggler={toggler}
        sources={[
          property.imageView2,
          property.imageView3,
          property.imageView4,
          property.imageView5,
          property.imageView6,
          property.imageView7,
          property.imageView8,
          property.imageView9,
          property.imageView10,
          property.imageView11,
          property.imageView12,
          property.imageView13,
          property.imageView14,
          property.imageView15,
          property.imageView16,
          property.imageView17,
          property.imageView18,
          property.imageView19,

        ].filter(source => source != null)}

        
			/>



		 </>
	);
}

export default Lightbox;