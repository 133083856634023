import React from 'react';
import '../components/css/about.css'
import Preselling from '../components/preselling2'
import Footer from '../components/Footer'
import img from '../assets/img/lindgren/img.PNG'
import img2 from '../assets/img/lindgren/img.PNG'

const Lindgren = () => {
  return (
    <div>
        <p className='text-center text-4xl lg:text-[37px] font-bold pt-10 mx-auto mt-20'>The Lindgren</p>

        <p className='text-center text-4xl lg:text-[25px] font-semibold'> at Arden Botanical Estate
        <br></br>

</p>

        <section className="dark:bg-gray-800 dark:text-gray-100 ">
            <div className=" px-6 py-12 mx-auto">
                <div className='container  mx-auto text-center'>
                YOUR HOME IN SOUTH’S
                  MOST PRIVILEGED COMMUNITY
                  This 17-hectare community is built to inspire and touch the lives of its residents. With its Scandinavian inspiration, it is a home that reconnects one to nature and encourages them to live a balanced life. Deeper than just the senses, The Lindgren is a village that affects the totality of one’s well-being.
                <br></br>


                </div>
                <br></br>
                <br></br>
                <Preselling />
                <br></br>
                <br></br>
                <p className='text-center p-2'>The Developer Megaworld subsidiary, Global-Estate Resorts, Inc. (GERI) is the leading developer of tourism estates in the Philippines. For more than 25 years in the industry, GERI established projects that are globally recognized such as Tagaytay Twin Lakes and Boracay Newcoast. Joining this roster of world-class projects, The Lindgren is yet another prestigious development by GERI.</p>

            </div>
        </section>
        
        <img className='mx-auto rounded rounded-tr-[100px] rounded-bl-[100px] dark:bg-gray-800 border border-gray-400' alt='' src={img}></img>
        <br></br>
        <img className='mx-auto rounded rounded-tr-[100px] rounded-bl-[100px] dark:bg-gray-800 border border-gray-400' alt='' src={img2}></img>
        <br></br>
        <Footer />
    </div>

  );
};

export default Lindgren;