import React from 'react';
import img from '../assets/img/about.jpg'
import img2 from '../assets/img/immio.jpg'
import '../components/css/about.css'
import Footer from '../components/Footer'


const About = () => {
  return (
    <section className='about bg-white mx-auto'>
       <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto mt-32 ">
        </h2>
        <section className="dark:bg-gray-800 dark:text-gray-100 ">
          <div className="container px-4 py-1 mx-auto">
            <div className="grid items-center gap-4 xl:grid-cols-5">
              <div className="max-w-2xl mx-auto my-8 space-y-4 text-center xl:col-span-2 xl:text-left">
                <h2 className="text-4xl text-[40px] font-bold">
    
                  About Us <br></br><br></br><br></br>Urban Compass Realty
                </h2>
              </div>
                  
            </div>
            <div className='grid gap-4'>
              Urban Compass Realty is a real estate agency based in Manila, Philippines with large and top-grade real estate offers located within and outside the metro. Friends turned business partners, realtor, Jean Hutt and lawyer, Marie Christine Duran-Schulze, started Urban Compass Realty in 2018 to cater to the growing number of real estate clients in need of first-rate listings in addition to topnotch service.<br></br><br></br> With a mission to be the Philippines’ most respected service brand in the real estate industry, they strive to foster a culture of learning, in true partnership with their agents, so they can excel and provide the best possible service to our local and foreign clientele.
 

              The company is manned by a specialized team of licensed and registered real estate agents, equipped with all the information a client needs when buying or selling a property and/or leasing the same. <br></br><br></br>The team is meticulously trained to guide their clients throughout the entire buying and/or leasing process. Each of the staff speaks fluent English which is a great advantage in their day-to-day business as the company works with clients and other companies from around the world.   Urban Compass has built a long term relationship with its clients and banks issuccess on their utmost commitment to their employees and partners, and  insurmountable dedication to quality of service and customer satisfaction.
            </div>   
          </div>
          
          <Footer />

        </section>
      
      </section>
    

  );
};

export default About;