import React from 'react';
import '../components/css/about.css'
import {displayAgent} from '../data'
import img from '../assets/img/agents/jet.JPG'
import img1 from '../assets/img/agents/jed.jpg'


const About = () => {
  return ( <>
  
  <section class="dark:bg-gray-900 mt-32">
    <div class="h-[32rem] dark:bg-gray-800">
        <div class="container px-6 py-10 mx-auto">
            <h1 class="text-3xl font-semibold text-center text-gray-800 capitalize lg:text-4xl dark:text-white">The Executive Team</h1>

            <div class="flex justify-center mx-auto mt-6">
                <span class="inline-block w-40 h-1 bg-blue-500 rounded-full"></span>
                <span class="inline-block w-3 h-1 mx-1 bg-blue-500 rounded-full"></span>
                <span class="inline-block w-1 h-1 bg-blue-500 rounded-full"></span>
            </div>

            <p class="max-w-2xl mx-auto mt-6 text-center text-gray-500 dark:text-gray-300">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo incidunt ex placeat modi magni quia error alias, adipisci rem similique, at omnis eligendi optio eos harum.
            </p>
        </div>
    </div>

    <div class="container px-6 py-10 mx-auto -mt-72 sm:-mt-80 md:-mt-96">
        <div class="grid grid-cols-1 gap-8 mt-8 xl:mt-16 md:grid-cols-2 xl:grid-cols-3">
           


            <div class="flex flex-col items-center p-4 border sm:p-6 rounded-xl dark:border-gray-700">
                <img class="object-cover w-full rounded-xl aspect-square" alt=""/>

                <h1 class="mt-4 text-2xl font-semibold text-gray-700 capitalize dark:text-white"> Coming Soon</h1>

                <p class="mt-2 text-gray-500 capitalize dark:text-gray-300">Coming Soon</p>

                <div class="flex mt-3 -mx-2">
                {displayAgent.map((items, index) => (
                    <div className='grid grid-cols-6 gap-3' key={index}>
                      <a href='https://www.facebook.com/'>
                        <img className='object-fill h-10 w-10' src={items.socialIcon1} alt='' />
                      </a>
                      <img className='object-fill h-10 w-10 border border-gray-300 rounded-full' src={items.socialIcon2} alt='' />
                      <img className='object-fill h-10 w-10' src={items.socialIcon3} alt='' />
                      <a href='https://www.google.com/joshuapenamante0209@gmail.com'><img className='object-fill h-10 w-10' src={items.socialIcon4} alt='' /></a>
                      <a href='https://www.instagram.com/ucompassrealty/?igshid=1vtzl5urh3waz'><img className='object-fill h-10 w-10' src={items.socialIcon5} alt='' /></a>
                      <a href='https://www.youtube.com/channel/UCfkrMIIv0GxWK017QavcYTA'><img className='object-fill h-10 w-10' src={items.socialIcon6} alt='' /></a>
                    </div>
                  ))}
                </div>
            </div>
        </div>
    </div>
</section>
    
    </>
  );
};



export default About;