import React, {useContext, useState} from 'react'
import { Link } from 'react-router-dom';
import Search from './Search'
import bannerImage from '../assets/img/logo/banner2.jpg';
import TypeWriter from 'typewriter-effect';
import { HouseContext } from './HouseContext';
import House from './House';
import { ImSpinner10 } from 'react-icons/im';


import CountryDropdown from './CountryDropdown';
import PropertyDropdown from './PropertyDropdown';
import PriceRangeDropdown from './PriceRangeDropdown';

import {RiSearch2Line} from 'react-icons/ri';


const Buy = () => {
  const {handleClick} = useContext(HouseContext)
  const { houses, loading } = useContext(HouseContext);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSearchFilter = (event) => {
    setSearchValue(event.target.value);
  };

  const filteredHouses = houses.filter((house) => {
    
    const nameMatch = house.name.toLowerCase().includes(searchValue.toLowerCase());
    const statusMatch = house.status.toLowerCase().includes(searchValue.toLowerCase());
    const countryMatch = house.country.toLowerCase().includes(searchValue.toLowerCase());
    const addressMatch = house.address.toLowerCase().includes(searchValue.toLowerCase());
    const descriptionMatch = house.description.toLowerCase().includes(searchValue.toLowerCase());
    
    return nameMatch || statusMatch || countryMatch || addressMatch || descriptionMatch;
  });


  if (loading) {
    return (
      <ImSpinner10 className='mx-auto animate-spin text-blue-500 text-4xl mt-[300px] text-[72px]' />
    );
  }

  return (
    <>
      <section className="back bg-gray-800 dark:bg-gray-800 text-white dark:text-gray-100 mt-5 max-auto border border-gray-500" style={{ 
        backgroundImage: `url(${bannerImage})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "50vh",
        width: "100%",
        }}>

        <div className="container mx-auto mt-10 h-full flex flex-col justify-center">
          <div className="items-center">
            <h1 className=' font-semibold text-center mx-auto text-5xl md:text-7xl lg:text-8xl'>
              Your Compass in
            </h1>
            <h1 className=' font-semibold text-center mx-auto text-5xl md:text-7xl lg:text-8xl'>
              Real Estate
            </h1>

            <div className="flex justify-center mt-6 space-x-1">
              <Link to='/buy'>
              <button
                className="px-4 py-2 font-bold tracking-wide text-green-500 capitalize transition-colors duration-200 transform bg-white border border-green-500 rounded-md hover:bg-green-500 hover:text-white focus:outline-none focus:bg-blue-50 w-44 flex items-center justify-center"
                onClick={() => {
                  setIsLoading(true);
                  handleClick();
                  setTimeout(() => {
                    setIsLoading(false);
                  }, 1000);
                }}
              >
                {isLoading ? (
                  <RiSearch2Line className="animate-spin mr-2" />
                ) : (
                  <>
                    <span className="mr-2">BUY</span>
                   
                  </>
                )}
              </button>


              </Link>
              <Link to='/rent'>
              <button
                className="px-4 py-2 font-bold tracking-wide text-green-500 capitalize transition-colors duration-200 transform bg-white border border-green-500 rounded-md hover:bg-green-500 hover:text-white focus:outline-none focus:bg-blue-50 w-44 flex items-center justify-center"
                onClick={() => {
                  setIsLoading(true);
                  handleClick();
                  setTimeout(() => {
                    setIsLoading(false);
                  }, 1000);
                }}
              >
                {isLoading ? (
                  <RiSearch2Line className="animate-spin mr-2" />
                ) : (
                  <>
                    <span className="mr-2">RENT</span>
                   
                  </>
                )}
              </button>
              </Link>

            </div>
          </div>
          <div className='text-center text-4xl lg:text-[37px] font-semibold mt-5'>
            <TypeWriter
              options ={{
                strings: ['Buy Properties' ],
                autoStart: true,
                loop: true.valueOf,
              }}
            />
          </div>
        </div>
      </section>

      <div className="mx-auto">
        <p className='font-bold'>Filter settings</p>
        <div className='px-[15px] py-2 max-w-[1440px] mx-auto flex flex-col lg:flex-row justify-between gap-4 lg:gap-x-3 relative lg:top-100 lg:shadow-1 bg-white rounded-lg md:border-4'>
          <CountryDropdown />
          <PropertyDropdown />
          <PriceRangeDropdown />
          <button  onClick={()=> handleClick()} className='bg-orange-700 hover:bg-blue-800 transition w-full lg:max-w-[120px] h-50 rounded-lg flex justify-center items-center text-white text-lg'>
            Search &nbsp; <RiSearch2Line  /> 
          </button>
        </div>
      </div>
     
    
    <div style={{ display: "flex", justifyContent: "center" }} className='mt-10'>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px",
          border: "1px solid #000",
          background: "#fff",
          width: "50px",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{ width: "20px", height: "20px" }}
        >
          <circle cx="11" cy="11" r="8" />
          <line x1="21" y1="21" x2="16.65" y2="16.65" />
        </svg>
      </div>
      <input
        type="search"
        placeholder="Search Location, Property, etc..."
        value={searchValue}
        onChange={handleSearchFilter}
        style={{
          display: "block",
          padding: "10px",
          borderTopRightRadius: "5px",
          borderBottomRightRadius: "5px",
          border: "1px solid #000",
          fontSize: "16px",
          width: "70%",
          marginLeft: "-1px",
        }}
      />
    </div>





   <section className=''>
      
      <div className='p-10 mx-auto'>
        <div className='grid md:grid-cols-2 lg:grid-cols-5 gap-4 lg:gap-9'>
        
  
        {filteredHouses.filter(house =>house.status === 'For Sale').map((house, index) => (
          <Link to={`/property/${house.id}`} key={index}>
            <House house={house} />
          </Link>
        ))}
   
        </div>
      </div>
    </section>
    </>
  )
}

export default Buy