import React from "react"
import { housesData } from "../data"

const Featured = () => {
  return (
    <>
   sadasds
    </>
  )
}

export default Featured
