import React from 'react';
import '../components/css/about.css'
import Preselling from '../components/preselling5'
import Footer from '../components/Footer'


const Hamptons = () => {
  return (
    <div>
        <p className='text-center text-4xl lg:text-[37px] font-bold pt-10 mt-20 mx-auto'>The Hamptons</p>

        <p className='text-center text-4xl lg:text-[25px] font-semibold'> Caliraya
        <br></br>

</p>

        <section className="dark:bg-gray-800 dark:text-gray-100 ">
            <div className=" px-6 py-12 mx-auto">
                <div className='container  mx-auto text-center'>
                Welcome to The First Lakeside Lifestyle Community in the Philippines by Megaworld – Global Estate Resorts, Inc.

 

Spend your weekend by strolling along The Hamptons Village while appreciating the picture-perfect surrounding made refreshing and calming by nature. Reserve a Premier lot today and live a laid back lifestyle here at The Hamptons Caliraya.

 

 

Inspired by the posh and exclusive image of The Hamptons in New York, the natural beauty of Lake Caliraya and the concept of an Integrated Lifestyle Community, this 300-hectare development will be the Country’s Next Prestigious Tourism Destination called The Hamptons Caliraya which will feature residential lots for sale at The Hamptons Village. Only 112 lots to be sold ranging from 530sqm to 1423sqm with your choice of Fairway View Lot, Lake View Lot and the prime Lakeside Lot where you can have your own access to the lake and build your own dock for your boat, yacht, jet ski or if you just want to enjoy fishing or swimming.

 
                <br></br>


                </div>
                <br></br>
                <br></br>
                <Preselling />


            </div>
        </section>
        
        <Footer />
    </div>

  );
};

export default Hamptons;