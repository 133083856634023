import React from 'react';

import Footer from './Footer';
import ReactPlayer from 'react-player'

import '../components/css/player.css'

const Services = () => {
  return (
    <>

<div className="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-5 lg:py-5 mt-32">
      <div className="max-w-xl mb-1 md:mx-auto sm:text-center lg:max-w-2xl md:mb-2">
        <div>
          <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
            News
          </p>
        </div>
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          <span className="relative inline-block">
            
            <span className="relative">Get the</span>
          </span>{' '}
          latest breaking news on the Philippines and the world of Real Estate.
        </h2>
      </div>
    </div>


      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-8 lg:grid-cols-3 sm:max-w-sm sm:mx-auto lg:max-w-full">
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded shadow-sm">
          <div className='wrapper'>
              <ReactPlayer
                className='player '
                url="https://www.youtube.com/watch?v=vXNCTognDBM&ab_channel=UrbanCompassRealty"
                width='100%'
                height='100%'
              />
            </div>
          <div className="p-5 border border-t-0">

            <a
              href="/"
              aria-label="Category"
              title="Visit the East"
              className="inline-block mb-3 text-2xl font-bold leading-5 transition-colors duration-200 hover:text-deep-purple-accent-700"
            >
              Beautiful Newly Built Modern House & Lot For Sale at Manila Southwoods Residential Estates
            </a>
            <p className="mb-2 text-gray-700">
            Selling Price : Php 95 Million <br></br>

Location: Manila Southwoods Residential Eatates 
            </p>
 
          </div>
        </div>
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded shadow-sm">
          <div className='wrapper'>
              <ReactPlayer
                className='player '
                url="https://www.youtube.com/watch?v=0KGLlG_XBnA&ab_channel=UrbanCompassRealty"
                width='100%'
                height='100%'
              />
            </div>
          <div className="p-5 border border-t-0">

            <a
              href="/"
              aria-label="Category"
              title="Visit the East"
              className="inline-block mb-3 text-2xl font-bold leading-5 transition-colors duration-200 hover:text-deep-purple-accent-700"
            >
              Interiored Spacious Fully Furnished 3 bedrooms with balcony The PROSCENIUM at Rockwell.
            </a>
            <p className="mb-2 text-gray-700">
            Asking Price: 280k/monthly

Selling Price : PHP 80 Million (negotiable)<br></br>

Location: Proscenium 
            </p>
 
          </div>
        </div>
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded shadow-sm">
          <div className='wrapper'>
              <ReactPlayer
                className='player '
                url="https://www.youtube.com/watch?v=xvtq4gpNFOw&ab_channel=UrbanCompassRealty"
                width='100%'
                height='100%'
              />
            </div>
          <div className="p-5 border border-t-0">

            <a
              href="/"
              aria-label="Category"
              title="Visit the East"
              className="inline-block mb-3 text-2xl font-bold leading-5 transition-colors duration-200 hover:text-deep-purple-accent-700"
            >
              PERFECT BALI VIBES - BEACH HOUSE FOR SALE At TALI BATANGAS
            </a>
            <p className="mb-2 text-gray-700">
            Selling Price: Php 38 Million<br></br>
            Location: Tali Batangas
            </p>
 
          </div>
        </div>
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded shadow-sm">
          <div className='wrapper'>
              <ReactPlayer
                className='player '
                url="https://www.youtube.com/watch?v=GPbk-LlDcZU&ab_channel=UrbanCompassRealty"
                width='100%'
                height='100%'
              />
            </div>
          <div className="p-5 border border-t-0">

            <a
              href="/"
              aria-label="Category"
              title="Visit the East"
              className="inline-block mb-3 text-2xl font-bold leading-5 transition-colors duration-200 hover:text-deep-purple-accent-700"
            >
              FOR SALE - Beautiful Brand New Modern House and Lot at Ayala Alabang Village
            </a>
            <p className="mb-2 text-gray-700">
            Selling Price: Php 230 Million<br></br>
            Location: Ayala Alabang Village
            </p>
 
          </div>
        </div>
        
        
      </div>
    </div>
  <Footer/>
    </>
  );
} 

export default Services;