import React from 'react';
import '../components/css/about.css'
import Preselling from '../components/preselling3'
import Footer from '../components/Footer'

const Mckinley = () => {
  return (
    <div>
        <p className='text-center text-4xl lg:text-[37px] font-bold pt-10 mx-auto mt-20'>McKinley West</p>

        <p className='text-center text-4xl lg:text-[25px] font-semibold'> Taguig City
        <br></br>

</p>

        <section className="dark:bg-gray-800 dark:text-gray-100 ">
            <div className=" px-6 py-12 mx-auto">
                <div className='container  mx-auto text-center'>
                La belle vie brought into the 21st century. Catering to Manila’s upscale market, Megaworld is developing McKinley West, a 34.5 hectare ultra high-end township on the former JUSMAG property in Fort Bonifacio beside Forbes Park and Manila Polo Club.
                <br></br><br></br>
                The company will spend P45 billion in the next 10 years to build McKinley West. Aside from the residential village where lots have appreciated as much as 110 percent since its launch in 2010, McKinley West will have rows of luxury residential estates with some properties having their own state-of-the-art security features and first-of-its-kind luxury amenities.
                <br></br><br></br>
                The posh residential enclave will be supplemented by a modern business district, living up to the lifestyle of neighboring Forbes Park. With the development of the township, the Bases Conversion and Development Authority has committed to expanding Lawton Avenue, giving McKinley West direct access to McKinley Hill, and more convenient access to Fort Bonifacio, South Luzon Expressway, C5 Road, the Ninoy Aquino International Airport and the Makati CBD. McKinley West’s unparalleled location and accessibility, together with its complete LIVE-WORK-PLAY-LEARN-SHOP lifestyle concept, make it the most ideal mixed-use community today.

                Live exceptionally at McKinley West.
                <br></br>


                </div>
                <br></br>
                <br></br>
                <Preselling />
                <br></br>
                <br></br>

            </div>
        </section>

        <Footer />
    </div>

  );
};

export default Mckinley;