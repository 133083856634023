import React from 'react';
import '../components/css/about.css'
import Preselling from '../components/preselling'
import Footer from '../components/Footer'

const Velaris = () => {
  return (
    <div>
        <p className='text-center text-4xl lg:text-[37px] font-bold pt-10'>The Velaris Residences</p>
        <br></br>
        <p className='text-center text-4xl lg:text-[25px] font-semibold'> AN ADDRESS FOR VICTORS AND CHAMPIONS
        <br></br>

</p>

        <section className="dark:bg-gray-800 dark:text-gray-100 ">
            <div className=" px-6 py-12 mx-auto">
                <div className='container  mx-auto text-center'>
                For those who have emerged victorious from life’s challenges, the successful few whose experience and expertise have given them a certain discernment, only the masterful will do. Whatever they see, hear, taste, touch, and possess must be in the realm of excellence and beyond. Their expectations are set high – in the careful curation of what they choose to experience, in their intention and confidence to accept only the outstanding – and they inspire us to do the same. They show a path to exceptional living for many to follow: operate at your best and expect nothing but the extraordinary.
                <br></br>
                <br></br>
                <br></br>
                
                And for them comes an unrivaled destination residence – The Velaris Residences by RHK Land Corporation, a joint venture of local real estate expert Robinsons Land Corporation (RLC) and international property development leader Hongkong Land Group (HKL). Along the C5 corridor and near the private estates of Valle Verde, Wack Wack, and Corinthian Gardens, the 45-storey testament to human progress and inspiration rises. From its unique façade to every careful choice of fixture and fitting, it is built to serve as a beacon of opulence, meant to represent the very best of us and the best in us. With access points through C5 Road, Ortigas Avenue, and Amang Rodriguez Avenue, it is at the center of Metro Manila and, for those who do not compromise on location, never too far from any section of the city. The Velaris Residences is an incomparable investment for those who steward success.
                </div>
                     <br></br>
                <br></br>
                <Preselling />
            </div>
        </section>
        <Footer />
    </div>

  );
};

export default Velaris;