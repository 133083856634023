import React from 'react';

import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import Contact from './components/contact';
import About from './components/about';
import Team from './components/team';
import Blog from './components/blog';
import PropertyDetails from './pages/PropertyDetails';
import Velaris from './components/velaris';
import Lindgren from './components/lindgren';
import Mckinley from './components/mckinley';
import Anaya from './components/anaya';
import Hamptons from './components/hamptons';
import HouseList from './components/HouseList';
import Featured from './components/Featured';
import New from './components/New';
import Rent from './components/rent';
import Buy from './components/buy';




const App = () => {
  return(
    <div className='max-w-[2560] mx-auto'  >
    
      <New />
      
      <Routes>
        <Route path='/' exact element={<Home />} />
        <Route path='/about' exact element={<About />} />
        <Route path='/blog' exact element={<Blog />} />
        <Route path='/contact' exact element={<Contact />} />
        <Route path='/team' exact element={<Team />} />
        <Route path='/velaris' exact element={<Velaris />} />
        <Route path='/lindgren' exact element={<Lindgren />} />
        <Route path='/mckinley' exact element={<Mckinley />} />
        <Route path='/anaya' exact element={<Anaya />} />
        <Route path='/hamptons' exact element={<Hamptons />} />
        <Route path='/houselist' exact element={<HouseList />} />
        <Route path='/featured' exact element={<Featured />} />
        <Route path='/new' exact element={<New />} />
        <Route path='/rent' exact element={<Rent />} />
        <Route path='/buy' exact element={<Buy />} />


        <Route path='/property/:id' element={<PropertyDetails />} />
      </Routes>

    </div>
  );
};


export default App;
