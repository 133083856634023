import React, { useContext, useState} from 'react';

import { HouseContext } from './HouseContext';
import House from './House';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import { ImSpinner10 } from 'react-icons/im';
import Banner from '../components/Banner'

import "../components/css/awards.css"
import "../components/css/footer.css"
import "../components/css/featured.css"
import Search from "../components/Search"


const HouseList = () => {
  const { houses, loading } = useContext(HouseContext);
  const [searchValue, setSearchValue] = useState("");

  const handleSearchFilter = (event) => {
    setSearchValue(event.target.value);
  };

  const filteredHouses = houses.filter((house) => {
    
    const nameMatch = house.name.toLowerCase().includes(searchValue.toLowerCase());
    const statusMatch = house.status.toLowerCase().includes(searchValue.toLowerCase());
    const countryMatch = house.country.toLowerCase().includes(searchValue.toLowerCase());
    const addressMatch = house.address.toLowerCase().includes(searchValue.toLowerCase());
    const descriptionMatch = house.description.toLowerCase().includes(searchValue.toLowerCase());
    
    return nameMatch || statusMatch || countryMatch || addressMatch || descriptionMatch;
  });

    if (loading) {
      return (
        <ImSpinner10 className='mx-auto animate-spin text-blue-500 text-4xl mt-[300px] text-[72px]' />
      );
    }

    if (houses.length < 1) {
      return (
        <div className='text-center text-1xl text-gray-400 mt-48 mb-'>
          Sorry, nothing was found. Refresh the Browser!
          <br></br>
          <button 
            className="bg-blue-400 hover:bg-blue-700 text-white py-2 px-4 border border-blue-200 rounded mt-8"
            onClick={() => window.location.reload()}
          >
            Refresh Browser
          </button>
        </div>
      );
    }

  return ( <div>

      <Banner />
      <Search />

    
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px",
          border: "1px solid #000",
          background: "#fff",
          width: "50px",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{ width: "20px", height: "20px" }}
        >
          <circle cx="11" cy="11" r="8" />
          <line x1="21" y1="21" x2="16.65" y2="16.65" />
        </svg>
      </div>
      <input
        type="search"
        placeholder="Search Location, Property, etc..."
        value={searchValue}
        onChange={handleSearchFilter}
        style={{
          display: "block",
          padding: "10px",
          borderTopRightRadius: "5px",
          borderBottomRightRadius: "5px",
          border: "1px solid #000",
          fontSize: "16px",
          width: "70%",
          marginLeft: "-1px",
        }}
      />
    </div>


      
    <section className=''>
      
      <div className='p-10 mx-auto'>
        <div className='grid md:grid-cols-2 lg:grid-cols-5 gap-4 lg:gap-9'>
        
  
        {filteredHouses.map((house, index) => (
          <Link to={`/property/${house.id}`} key={index}>
            <House house={house} />
          </Link>
        ))}
   
        </div>
      </div>
    </section>

    <Footer />
    </div>
  );
};

export default HouseList;
