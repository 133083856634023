import React, { useState, useContext } from 'react';
// import icons
import { RiWallet3Line, RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';
// import headless ui components
import { Menu } from '@headlessui/react';
// import context
import { HouseContext } from './HouseContext';

const PriceRangeDropdown = () => {
  const { price, setPrice } = useContext(HouseContext);
  const [isOpen, setIsOpen] = useState(false);
  const prices = [
    {
      value: 'Price range (any)',
    },
    {
      value: '1,000,000.00 - 10,000,000.00',
    },
    {
      value: '11,000,000.00 - 20,000,000.00',
    },
    {
      value: '21,000,000.00 - 30,000,000.00',
    },
    {
      value: '31,000,000.00 - 40,000,000.00',
    },
    {
      value: '41,000,000.00 - 50,000,000.00',
    },
    {
      value: '51,000,000.00 - 60,000,000.00',
    },
    {
      value: '61,000,000.00 - 70,000,000.00',
    },
    {
      value: '71,000,000.00 - 80,000,000.00',
    },
    {
      value: '81,000,000.00 - 90,000,000.00',
    },
    {
      value: '91,000,000.00 - 100,000,000.00',
    },
    {
      value: '100,000,000.00 - 200,000,000.00',
    },
    {
      value: '200,000,000.00 - 500,000,000.00',
    },
  ];

  return (
    <Menu as='div' className='dropdown relative text-black' >
      <Menu.Button
        onClick={() => setIsOpen(!isOpen)}
        className='dropdown-btn w-full text-left lg:bg-white md:border-1 border-gray-400 md:border-gray-400'
      >
        <RiWallet3Line className='dropdown-icon-primary text-blue-500' />
        <div>
          <div className='text-[15px] font-medium leading-tight'>{price}</div>
          <div className='text-[13px]'>Choose your price range</div>
        </div>
        {isOpen ? (
          <RiArrowUpSLine className='dropdown-icon-secondary text-blue-500' />
        ) : (
          <RiArrowDownSLine className='dropdown-icon-secondary text-blue-500' />
        )}
      </Menu.Button>

      <Menu.Items className='dropdown-menu'>
        {prices.map((price, index) => {
          return (
            <Menu.Item as='li' onClick={() => setPrice(price.value)} key={index} className='cursor-pointer hover:text-red-600 transition text-black'>₱ {price.value}</Menu.Item>
          );
        })}
      </Menu.Items>
    </Menu>
  );
};

export default PriceRangeDropdown;