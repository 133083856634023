import React from 'react';
import '../components/css/about.css'
import Preselling from '../components/preselling4'
import Footer from '../components/Footer'

const Anaya = () => {
  return (
    <div>
        <p className='text-center text-4xl lg:text-[37px] font-bold pt-10 mt-20 mx-auto'>Anvaya Cove</p>

        <p className='text-center text-4xl lg:text-[25px] font-semibold'> Morong Bataan
        <br></br>

</p>

        <section className="dark:bg-gray-800 dark:text-gray-100 ">
            <div className=" px-6 py-12 mx-auto">
                <div className='container  mx-auto text-center'>
                Presenting the excitement of a seaside resort and the tranquility of a mountain retreat, unique tropical features provide residents with the best that nature has to offer. A 620-Hectare development designed for shared moments among family, Anvaya Cove offers endless opportunities to forge lifelong memories. A dynamic community nurturing family above everything else. With an array of amenities, services, events and activities, family ties flourish at Anvaya Code.
       

                </div>
                <br></br>
                <br></br>
                <Preselling />
                <br></br>
                <br></br>

            </div>
        </section>

        <Footer />
    </div>

  );
};

export default Anaya;