import React from 'react';
import {useContext} from 'react';

import CountryDropdown from './CountryDropdown';
import PropertyDropdown from './PropertyDropdown';
import PriceRangeDropdown from './PriceRangeDropdown';

import {RiSearch2Line} from 'react-icons/ri';
import {HouseContext} from './HouseContext';

const Search = () => {
  const {handleClick} = useContext(HouseContext)
  return (


    <section className="dark:bg-gray-800 dark:text-gray-100 ">
      <div className="px-6 py-12 mx-auto">
      <p className='font-bold'>Filter settings</p>
        <div className='px-[15px] py-2 max-w-[1440px] mx-auto flex flex-col lg:flex-row justify-between gap-4 lg:gap-x-3 relative lg:top-100 lg:shadow-1 bg-white rounded-lg md:border-4'>
        <CountryDropdown />
        <PropertyDropdown />
        <PriceRangeDropdown />
          <button  onClick={()=> handleClick()} className='bg-orange-700 hover:bg-blue-800 transition w-full lg:max-w-[120px] h-50 rounded-lg flex justify-center items-center text-white text-lg'>
            Search &nbsp; <RiSearch2Line  /> 
          </button>
        </div>
      </div>
    </section>


  );
};

export default Search;
