import { Fragment } from 'react'
import { motion } from "framer-motion";
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, HomeIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import Logo from '../assets/img/logo-2.png';

const navigation = [
  { 
    name: 
    <Link to='/' className='flex items-center text-2xl'>
      Home
    </Link>,
    current: false 
  },

  { 
    name: 
    <Link to='/houselist' className='flex items-center  text-2xl'>
      Properties
    </Link>, 
    current: false 
  },

  { 
    name: 
    <Link to='/blog' className='flex items-center  text-2xl'>
      Vlog
    </Link>, 
    current: false 
  },


  { 
    name: 
    <Link to='/about' className='flex items-center  text-2xl'>
      About Us
    </Link>, 
    current: false 
  },

  { 
    name: 
    <Link to='/contact' className='flex items-center  text-2xl'>
      Contact Us
    </Link>, 
    current: false 
  },
]

const navigation_right_1 = [
  { 
    name: 
    <a href='https://docs.google.com/forms/d/1jIH_Cc9WQ1aEcoVEXYHyar--FRfRIU22KCNoCAkxj40' className='flex items-center text-base'>
      

      Enlist with Us &nbsp;
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
</svg>
    </a>, 
    current: true 
  },
]




function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  return (
    <Disclosure as="nav" className="bg-gray-200 fixed top-0 w-full" style={{ width: '100%', zIndex: 999 }}>


      {({ open }) => (
        <>
          <div className="mx-auto max-w-8xl px-2 sm:px-6 lg:px-3 mt-3 mb-4">
            <div className="relative flex h-16 items-center justify-between">
              <div className="flex flex-shrink-0 items-center">
                <Link to='/' className='flex items-center text-base'> 
                  <img
                    className="block h-28 w-auto lg:hidden"
                    src={Logo}
                    width={400}
                    alt="Your Company"
                  />
                  <img
                    className="hidden h-28 w-auto lg:block"
                    src={Logo}
                    width={400}
                    alt="Your Company"
                  />
                </Link>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-black-600 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current ? 'bg-gray-600 text-white' : 'text-black hover:bg-orange-700 hover:text-white',
                          'px-3 py-2 rounded-md text-sm font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>


              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {navigation_right_1.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current ? 'bg-green-600 text-white' : 'text-gray-300 hover:bg-orange-700 hover:text-white',
                          'px-3 py-2 rounded-md text-sm font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </a>
                    ))}

                    
                  </div>
                </div>            
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden border border-gray-400 rounded-md rounded-tl-90px">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-gray-900 text-white' : 'text-black-300 hover:bg-gray-700 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
              {navigation_right_1.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-green-600 text-white' : 'text-black hover:bg-gray-700 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}

            </div>
            
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}